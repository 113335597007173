export default {
  btn: {
    scmy: '生成密钥',
    empty: '清空',
    removeConcerns: '移除关注',
    range: '仪表量程',
    threshold: '预警阈值',
    setting: '参数设置',
    applyModify: '应用修改',
    preview: '在线预览',
    add: '添加',
    delete: '删除',
    confirm: '完成',
    cancel: '取消',
    close: '关闭',
    reply: '回复',
    login: '登 录',
    loginByNameAndPas: '用户名密码登录',
    register: '注册账号',
    pleaseEnter: '请输入',
    pleaseChoose: '请选择',
    pleaseEnterMsg: '请输入{msg}',
    pleaseChooseMsg: '请选择{msg}',
    query: '筛选',
    search: '查询',
    refresh: '刷新',
    new: '新增',
    edit: '编辑',
    change: '修改',
    save: '保存',
    No: '序号',
    personSexandID: '人员姓名、统一ID',
    preciseQuery: '精确查询',
    remove: '移除',
    append: '追加',
    sure: '确定',
    curve: '曲线',
    data: '数据',
    exportData: '数据导出',
    chooseTime: '选择时间',
    reset: '重置',
    resetPassword: '重置密码',
    Disable: '禁用',
    Enable: '启用',
    associatedRoles: '关联角色',
    Tips: '提示',
    showMenuTree: '显示菜单树',
    FuzzyUserName: '人员模糊检索',
    FuzzySCodeorname: '名称模糊检索',
    FuzzySname: '姓名模糊检索',
    FuzzyAccount: '账号模糊查询',
    FuzzyGroupName: '组名称模糊检索',
    orgnizationName: '组织机构模糊检索',
    FuzzyJth: '井筒号模糊检索',
    keywordFilter: '输入关键字进行过滤',
    Associatpermission: '模块授权',
    AssociatMenu: '关联菜单',
    AssociatResources: '关联资源',
    Unassociatedmenu: '未关联菜单',
    associatedmenu: '已关联菜单',
    wellNoSearch: '井筒号检索',
    backPortal: '返回导航页',
    userHandbook: '用户手册',
    UserAuthorization: '用户授权',
    VideoRoomEnable: '启用',
    VideoRoomForbidden: '关闭',
    VideoRoomEnter: '进入',
    clientDownLoad: '客户端下载',
    dailyUpload: '附件上传',
    appendix: '附件',
    appendixDownLoad: '附件下载',
    appendixDelete: '附件删除',
    examine: '审核',
    send: '发送',
    saveAndSend: '保存并发送',
    forbidden: '禁用',
    risk: '风险预警',
    WindowsDownload: 'windows电脑版下载',
    macAppleDownload: 'Mac苹果电脑版下载',
    received: '已接收',
    sent: '已发送',
    focusOnWell: '关注井管理',
    showWellLog: '查询全井曲线',
    deploy: '部署',
    constructionRecord: '施工记录',
    mudLog: '钻井液监测',
    jdSearch: '井队检索',
    qxrqSearch: '取心日期检索',
    contrastWell: '多井对比',
    menu: '导航菜单',
    import: '导入',
    downloadTemplate: '下载模板',
    exportFailedData: '导出失败数据',
    upload: '上传',
    download: '下载',
    changeTimes: '修改时间段',
    addMessage: '发送消息',
    show: '显示',
    hide: '隐藏',
    custom: '自定义',
    change2: '更改',
    modifyAlarmTime: '修改报警时间',
    batchPersonnelProcessing: '批量人员处理',
    viewProcessing: '查看处理',
    startCalculation: '开始计算',
    endCalculation: '停止计算',
    resetHST: '初始化视频会议',
    resetHelmet: '初始化智能头盔',
    initialize: '初始化',
    functionAssociation: '功能授权',
    relevantInformationQuery: '相关信息查询',
    calculationLog: '计算日志',
    automaticRetrieval: '输入后自动检索',
    artificialWarning: '人工预警',
    feedback: '意见反馈',
    startDate: '开始日期',
    endDate: '结束日期',
    wellNew: '监测',
    savePdf: '生成pdf',
    addUsers: '添加用户',
    deleteUsers: '删除用户',
    importDesign: '导入设计阈值',
    buildUnit: '建设单位',
    constructionUnit: '施工单位',
    kaizuan: '开钻',
    daizuan: '待钻',
    visibleUnits: '可见单位'
  },
  tiles: {
    singleWellQuery: '单井信息查询',
    multiInfoCompare: '多井信息对比',
    wellTracking: '钻井跟踪',
    regionalComplexStatistics: '区域复杂情况统计',
    StaticDataEntry: '数据管理',
    videoMeeting: '视频会议',
    videoSurveillance: '视频监控',
    fracturingTracking: '压裂跟踪与分析',
    systemManagement: '系统管理',
    resultDocQuery: '成果文档查询',
    search: '高级检索',
    XBJ: '钻井信息集成-地理信息导航',
    addUser: '新增用户',
    editUser: '编辑用户',
    examineUser: '审核用户',
    fracturing: '酸化压裂分析',
    wellInfo: '井基本信息',
    GeographicInfo: '地理信息',
    relatedInfo: '相关井信息',
    InputInfo: '录入信息',
    wellpath: '轨道设计分点数据',
    addVideoRoom: '创建会议室',
    editVideoRoom: '修改会议室',
    addWarnLevel: '新增故障等级',
    editWarnLevel: '编辑故障等级',
    addwarnConfig: '新增预警配置',
    editwarnConfig: '编辑预警配置',
    PetroleumGasTestMonitoring: '完测跟踪',
    Integrateddisplay: '钻井信息分析',
    comingSoon: '敬请期待',
    editMessage: '编辑消息',
    addMessage: '新增消息',
    videoRoomCode: '会议室编号',
    videoRoomName: '会议室名称',
    videoRoomStatus: '会议室状态',
    complexPromptOfAdjacentWell: '邻井复杂提示',
    noComplexPromptOfAdjacentWell: '暂无邻井复杂情况',
    dealWith: '我来处理',
    changeAlarmLevel: '更改预警级别',
    selectedUsers: '已选用户',
    associateMoreUsers: '关联更多用户',
    szjcsj: '实钻基础数据',
    szjygj: '实钻井眼轨迹',
    szzjmx: '实钻钻具明细'
  },
  navbar: {
    language: '语言',
    logOut: '退出登录',
    dashboard: '首页',
    screenfull: '全屏',
    personal: '个人中心',
    theme: '主题',
    dimension: '量纲'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    sinopecAuth: '认证登录',
    userLogin: '用户登录',
    username: '用户名',
    password: '密码',
    autoLogin: '自动登录',
    verificationCode: '验证码',
    forgetPassword: '忘记密码',
    signUp: '注册账户',
    pleaseEnterVerificationCode: '请输入验证码',
    verificationCodeError: '验证码有误',
    passLogin: '账号密码登录'
  },
  user: {
    name: '姓名',
    account: '账号',
    company: '所属公司',
    status: '状态',
    lastupdated: '最后更新时间',
    title: '标题',
    content: '内容',
    sendDate: '发送时间',
    receiveUserName: '接收人',
    statusName: '状态',
    sendUser: '发送人',
    msg: '消息',
    receiveUser: '回复人',
    receiveDate: '回复时间',
    read: '已读',
    noRead: '未读',
    send: '已发送',
    save: '已保存',
    checkMsg: '选择要查看的消息'
  },
  permission: {
    roles: '你的角色',
    switchRoles: '选择角色'
  },
  errorLog: {},
  well: {
    time: '时间',
    percent: '百分比',
    wellNo: '井号',
    jtNum: '井筒号',
    color: '颜色',
    al: '预警记录',
    cd: '曲线显示',
    dd: '数据显示',
    vhd: '查看历史数据',
    road: '道',
    number: '井号',
    numberList: '井筒号列表',
    currentWorkingCondition: '当前工况',
    hover: '悬停',
    nowTime: '当前时间',
    sunbei53X: '顺北53X井',
    sunbei63X: '顺北63X井',
    sunnan53X: '顺南54X井',
    DesignWellDepth: '设计井深',
    CurrentDepth: '钻达井深',
    BITDepthM: '钻头位置(m)',
    TurntableTorque: '转盘扭矩(kN·m)',
    Torque: '转盘扭矩',
    RealtimeDrillingInformation: '钻井实时信息',
    WellDepth: '钻达井深',
    BITDepth: '钻头位置',
    WaterParameter: '水力/气测参数',
    FLWIN: '入口流量(L/s)',
    FLWIN1: '入口流量',
    FLWOUT: '出口流量(%)',
    FLWOUT1: '出口流量',
    SUM1: '总池体积(m³)',
    SUM: '总池体积',
    TemperatureParameter: '温度参数',
    showData: '展开数据',
    TMPIN: '入口温度',
    TMPOUT: '出口温度',
    GasLoggingParameters: '气测参数',
    CalculationParamter: '计算参数',
    PoolVolumeParameters: '池体积参数',
    TGASma: '总烃(%)',
    TGASma1: '总烃',
    CollectionTime: '采集时间',
    StandardWellDepth: '标准井深(m)',
    TVDDepth: '垂直井深(m)',
    LAGDepth: '迟到井深(m)',
    HKHGHT: '大钩位置',
    WOH: '大钩负荷(kN)',
    WOH1: '大钩负荷',
    WOB: '钻压(kN)',
    RPM: '转速(r/min)',
    SPP: '立管压力(MPa)',
    spp: '立管压力',
    DQNJ: '顶驱扭矩(kN·m)',
    WHP: '套管压力(MPa)',
    ROP: '钻时(min/m)',
    PIT1: '池体积1(m³)',
    pit1: '池体积1',
    PIT2: '池体积2(m³)',
    pit2: '池体积2',
    PIT3: '池体积3(m³)',
    pit3: '池体积3',
    PIT4: '池体积4(m³)',
    pit4: '池体积4',
    PIT5: '池体积5(m³)',
    pit5: '池体积5',
    PIT6: '池体积6(m³)',
    pit6: '池体积6',
    PUMP1: '一号泵冲(rpm)',
    PUMP2: '二号泵冲(rpm)',
    FLWPUMPS: '总泵冲(rpm)',
    MWIN: '入口密度(g/cm³)',
    MWIN1: '入口密度',
    EDensity: '入口密度(L/s)',
    MWOUT: '出口密度(g/cm³)',
    MWOUT1: '出口密度',
    CONIN: '入口电导(S/m)',
    CONOUT: '出口电导(S/m)',
    TMPINC: '入口温度(℃)',
    TMPOUTC: '出口温度(℃)',
    C1main: '甲烷(%)',
    C2main: '乙烷(%)',
    C3main: '丙烷(%)',
    nC4main: '正丁烷(%)',
    iC4main: '异丁烷(%)',
    nC5main: '正戊烷(%)',
    iC5main: '异戊烷(%)',
    h2: '氢气(%)',
    CO2gen: '二氧化碳(%)',
    H2S1Max: '硫化氢1(ppm)',
    H2S2Max: '硫化氢2(ppm)',
    RealtimeData: '实时数据',
    ListData: '参数列表',
    EngineeringParameter: '工程参数',
    SlurryParameter: '泥浆参数',
    DCexponential: 'DC指数',
    DrillTorque: '顶驱扭矩',
    MechanicalSpecificEnergyofDrillBit: '钻头机械比能',
    BottomHole: '井底ECD',
    BottomHolePressure: '井底地层压力(MPa)',
    PureDrillingTimeRatio: '纯钻时间(%)',
    CasingShoe: '套管鞋ECD',
    CasingShoePressure: '套管鞋地层压力(MPa)',
    DrillingTimeRatio: '起下钻时间(%)',
    DrillingPressure: '钻压',
    VerticalWelldepth: '垂直井深(m)',
    RotatingSpeed: '转盘转速(r/min)',
    EntranceVolume: '入口流量(L/s)',
    ExportVolume: '出口流量(%)',
    Hookheight: '大钩高度',
    Bitdepth: '钻头深度',
    Welldepthdesign: '设计井深',
    wellLogIndex: '第{msg}组曲线',
    logSetting: '曲线设置',
    line1: '曲线一',
    line2: '曲线二',
    line3: '曲线三',
    line4: '曲线四',
    linename: '曲线名称',
    min: '最小值',
    max: '最大值',
    unit: '单位',
    add: '新增曲线',
    currentDepth: '钻达井深',
    hide: '隐藏',
    indexTrackHistory: '时间',
    indexTrackMeter: '深度',
    event: '事件',
    reason: '原因',
    rq: '日期',
    visibleLimits: '可视区域时间范围',
    halfHour: '半小时',
    anHour: '一小时',
    twoHours: '两小时',
    tvb: '钻达垂深',
    horizon: '钻达层位',
    interruption: '数据中断',
    pushState: '传输状态',
    pureDrillingTime: '纯钻时间',
    nonPureDrillingTime: '非纯钻时间',
    computingTime: '计算时间',
    FullWellCurve: '全井曲线',
    jxzsdb: '机械钻速对比',
    scsxdb: '生产时效对比',
    ljfzxq: '邻井复杂详情',
    wellArea: '邻井半径范围',
    timeInterval: '时间间隔(s)',
    formationLithology: '地层岩性',
    frictionCoefficientSetting: '趋势线设置',
    settingOfWorkingCondition: '各工况曲线配置',
    fullDataAutoRegression: '全数据自动回归',
    regressionOfNodeData: '节点数据回归',
    trendlineFunction: '趋势线函数',
    frictionCoefficientOfCasing: '套管摩擦系数',
    coefficientSetting: '摩擦系数设置',
    openEyeFrictionCefficient: '裸眼摩擦系数',
    slurry: '泥浆',
    calculationOfHydraulicParameters: '水力参数计算',
    calculationOfDisplacementOfCementPlug: '打水泥塞顶替量计算',
    workOrderOfCardReleasing: '解卡工作单',
    volumeOfCommonDrillingTools: '（非计算）常用钻具容积',
    makeUpTorqueOfDrillingTool: '钻具上扣扭矩',
    casingMakeUpTorque: '（非计算）套管上扣扭矩',
    predictiveParameters: '预知参数',
    circulatingPressureConsumption: '循环压耗',
    bitHydraulic: '钻头水力',
    stringPerformanceTable: '（非计算）管柱性能表',
    casingStrengthCheck: '套管强度校核',
    punchNumberOfCylinderLiner: '不同缸套的冲数的换算',
    comparisonTable: '（非计算）井控装置常用钢圈螺栓对照表',
    md: '密度',
    pl: '排量',
    pressureDrop: '钻具内压力降',
    timeliness: '随钻时效',
    RealtimeMonitoring: '实时监测',
    daily: '钻井日报',
    schedul: '施工进度',
    szzjzhsyqksj: '钻具组合使用记录',
    szztsyqksj: '钻头使用记录',
    usageOfCoring: '取心记录', // 取心使用情况
    zjyxnsjsj: '钻井液取样记录',
    historyData: '录井回放',
    ownspace: '个人中心',
    fdhStart: '起始段(层)号',
    fdhEnd: '终止段(层)号',
    sectionNo: '段(层)号',
    waningValue: '油压预警值',
    fracturingInformation: '压裂信息',
    startTime: '开始时间',
    constructionTime: '建设时间',
    cumulative: '累计',
    oilPressure: '油压',
    casingPressure: '套压',
    displacement: '流量',
    totalLiquidVolume: '总流量',
    sandRatio: '砂比',
    totalSand: '总砂量',
    warningInfo: '报警信息'
  },
  msg: {
    zoomIn: '点击放大拖拽曲线曲线区域',
    zoomOut: '点击缩放曲线区域',
    addSuccess: '添加成功',
    addError: '添加失败',
    identity: '正在跳转',
    loginSuccess: '登录成功！',
    loginError: '登录失败！',
    details: '查看详情',
    pleaseChoose: '请选择需要添加的菜单',
    ComplexStatistics: '复杂情况统计',
    fnNo: '49号井',
    ffNo: '55号井',
    fsNo: '56号井',
    foNo: '61号井',
    DrillingDaily: '钻井日报',
    realtimeMonitor: '实时监控',
    DrillingSchedule: '钻井进度',
    deleteSuccess: '删除成功!',
    deleteFailed: '删除失败!',
    deleteUsedNot: '该项目已使用不能删除!',
    cancelDelete: '已取消删除',
    notNull: '不允许为空',
    deleteOrnot: '是否需要删除数据?',
    deleteWell: '是否移除该井?',
    forbidden: '是否禁用?',
    enterpasswordagain: '请再次输入密码！',
    twodonotmatch: '两次输入密码不一致！',
    existsNoadd: '已存在,请勿重复添加！',
    Lengthcharacters: '长度在 6 到 16 个字符！',
    LengthLimit: '长度应在 1 到 15 个字符！',
    LengthLimit10: '长度应在 1 到 10 个字符！',
    LengthLargeLimit: '长度应在 0 到 100 个字符！',
    LengthDyncLimit: '长度应在 {0} 到 {1} 个字符！',
    nochange: '数据并未改变',
    submitting: '提交中..',
    successful: '成功',
    failed: '失败',
    SinopecNorthWest: '中国石化西北分公司',
    numbervalidator: '请输入少于{msg1}位整数，少于{msg2}位小数！',
    numberdecimal: '请输入数字!',
    numberInteger: '请输入正数!',
    number1decimal: '请输入数字，最多一位小数!',
    number2decimal: '请输入数字，最多两位小数!',
    number3decimal: '请输入数字，最多三位小数!',
    number4decimal: '请输入数字，最多四位小数!',
    realTimeLogwarn: '已加载所有预读数据，查看更多请前往“历史数据”查看',
    ThePastHour: '过去1小时',
    ThePastThreeHours: '过去3小时',
    PastDay: '过去1天',
    PastThreeDays: '过去3天',
    LastWeek: '过去1周',
    customTime: '自定义时间',
    loadingLog: '正在加载前{msg}(分钟)的数据',
    saveSuccess: '保存成功！',
    saveError: '保存失败！',
    upOverDown: '下限低于上限',
    sendSuccess: '发送成功',
    sendError: '发送失败',
    noData: '暂无数据',
    noWarnMsg: '暂无风险预警信息',
    httpMsg: '数据更新至',
    updateError: '更新错误，请稍后重试！',
    updateOver: '更新完成！',
    gkSearch: '工况检索',
    fxmcSearch: '风险名称检索',
    yjdjSearch: '预警等级检索',
    fssjSearch: '请选择预警时间',
    dragFilesHere: '将文件拖到此处,或',
    clickUpload: '点击上传',
    onlyXlsx: '只能上传xlsx文件',
    dataExist: '数据重复，请修改主键数据再尝试！',
    chooseUser: '请选择人员',
    errorMsg: '服务异常',
    calculationResults: '计算结果',
    feedbackPlaceholder: '如果您在使用过程中有什么意见或建议，请留下您宝贵的意见或建议！',
    feedbackRule: '请输入200字以内的意见或建议！',
    feedbackSaveSuccess: '您的建议已提交处理。感谢您的宝贵意见！',
    passwordLength: '长度在 8 到 16 个字符',
    validatePassword: '必须含有数字，字母和字符'
  },
  units: {
    M: 'M',
    KM: 'KM'
  },
  sheet: {
    gysmc: '供应商名称',
    my: '密钥',
    sjjs: '设计井深',
    num: '数量',
    jsjg: '井身结构是否显示',
    thresholdSetting: '参数门限设置',
    stratumSetting: '岩层属性设置',
    jth: '井筒号',
    sgxq: '复杂故障详情',
    sgfsjs: '复杂故障发生井深',
    sgfzmc: '复杂故障名称',
    sgfzmx: '复杂故障明细',
    sgfssj: '复杂故障发生时间',
    sgfscs: '复杂故障发生次数',
    sgjcsj: '复杂故障解除时间',
    js: '钻达井深',
    ztjs: '钻头井深',
    cwjyx: '层位及岩性',
    fgjc: '返工进尺',
    bfjc: '报废进尺',
    sgsssj: '损失时间',
    zjjjss: '直接经济损失',
    clgk: '处理概况',
    sszjcd: '损失钻具长度',
    remarks: '备注',
    createBy: '录入人',
    cjr: '创建人',
    createDate: '录入日期',
    updateBy: '修改人',
    updateDate: '最近修改日期',
    delFlag: '删除标志',
    blockId: '区块ID',
    blockName: '区块名称',
    stratumName: '地层名称',
    DCDH: '地层代号',
    rockName: '主要岩石名称',
    topDepth: '顶界深度',
    bottomDepth: '底界深度',
    color: '岩石颜色',
    details: '岩性描述',
    progress: '钻井进度',
    all: '全部',
    detailed: '详细信息',
    list: '列表式',
    icon: '图标式',
    totalwell: '总井筒数',
    company: '公司',
    xz: '悬重',
    styqdw: '上提遇卡吨位',
    xfyzdw: '下提遇阻吨位',
    workGroupSheet: '工作组列表',
    personSheet: '人员列表',
    wellSheet: '井筒列表',
    groupname: '组名称：',
    wellno: '井号：',
    wellNo: '井号',
    groupId: '组ID',
    groupName: '组名称',
    remark: '备注',
    wellId: '井ID',
    JSSYTDW: '井所属油田单位',
    SSDW: '所属单位',
    block: '区块',
    JB: '井别',
    JX: '井型',
    ZJZT: '钻井状态',
    SFJH: '是否激活',
    GLDWID: '管理单位',
    SFFXJ: '是否分析井',
    SSZTZTJS:'实时钻头状态计算',
    groupManage: '组管理员',
    personnel: '人员',
    organization: '所属组织机构',
    isGroupManage: '是否群管理员',
    operat: '操作',
    ZJD: '钻井队',
    ZJDXCDH: '钻井队现场电话',
    LJD: '录井队',
    LJDXCDH: '录井队现场电话',
    YJSJ: '预警时间',
    GZMC: '故障名称',
    GZMS: '故障描述',
    FSJS: '井深',
    FSDC: '发生底层',
    Curvename: '曲线名称',
    min: '最小值',
    max: '最大值',
    unit: '单位',
    CurrentDepth: '当前井深',
    FirstReturn: '开次',
    WellTeam: '正钻井钻井进度',
    paramsName: '参数名称',
    paramsMin: '参数下限',
    paramsMax: '参数上限',
    paramsWarn: '参数预警',
    companyName: '油公司名称',
    mdc: '目的层',
    sjcs: '设计层深',
    zjmd: '钻井目的',
    wzyz: '完钻原则',
    wjff: '完井方法',
    sjdw: '设计单位',
    sjrq: '设计日期',
    jkzzb: '井口纵坐标',
    jkhzb: '井口横坐标',
    jdhzb: '井底横坐标',
    jdzzb: '井底纵坐标',
    jkjd: '井口经度',
    jkwd: '井口纬度',
    cpj: '磁偏角',
    cqj: '磁倾角',
    ccqd: '磁场强度',
    fwxzj: '方位修正角',
    dmhb: '地面海拔',
    gzwz: '构造位置',
    dlwz: '地理位置',
    csjf: '丛式井否',
    csjjzh: '丛式井井组号',
    csjzks: '丛式井组口数',
    csjzjyxh: '丛式井组井眼序号',
    fzjf: '分支井否',
    zjh: '主井号',
    fzs: '分支数',
    djfz: '第几分支',
    fzdjs: '分支点井深',
    czjf: '侧钻井否',
    czyjh: '侧钻原井号',
    czdjs: '侧钻点井深',
    lrr: '录入人',
    lrrq: '录入日期',
    xgr: '修改人',
    zjxgrq: '最近修改日期',
    remoteTransStart: '远传开始时间',
    remoteTransFinish: '远传结束时间',
    wellboreId: '井筒id',
    wellboreName: '井筒号',
    wellshape: '井型',
    drillstatus: '钻井状态',
    fracstatus: '压裂状态',
    islateral: '是否侧钻',
    isactive: '是否激活',
    startDate: '开始时间',
    suffixName: '表名后缀',
    ssljsjb: '录井数据视图',
    ssljmsjb: '录井米数据视图',
    sscjsjb: '测井数据视图',
    wcsssjb: '完测数据视图',
    ssylsjb: '压裂数据视图',
    mudDateSheet: '钻井液数据视图',
    sskysjb: '控压数据视图',
    ssecdsjb: 'ECD数据表',
    ssmsesjb: 'MSE数据表',
    ssgeopsjb: 'GEOP数据表',
    ssfftorquesjb: 'FFTORQUE数据表',
    DCYLXX: '地层压力信息', // title
    JDDS1: '井段顶深',
    JDDS2: '井段底深',
    DCYLDLMDXX: '地层压力当量密度下限',
    DCYLDLMDSX: '地层压力当量密度上限',
    PLYLDLMDXX: '破裂压力当量密度下限',
    PLYLDLMDSX: '破裂压力当量密度上限',
    TTYLDLMDXX: '坍塌压力当量密度下限',
    TTYLDLMDSX: '坍塌压力当量密度上限',
    DCFCXX: '地层分层信息', // title
    zjrbxx: '钻井日报信息', // title
    JIE: '界',
    XI: '系',
    TONG: '统',
    ZU: '组',
    DUAN: '段',
    YD: '亚段',
    QUN: '群',
    YSDM: '颜色代码',
    FXTS: '风险提示',
    jxj: '井斜角',
    fwj: '方位角',
    nzb: 'N坐标',
    ezb: 'E坐标',
    bhj: '闭合距',
    qjbhl: '全角变化率',
    jxbhl: '井斜变化率',
    fwbhl: '方位变化率',
    sptycd: '水平投影长度',
    tywy: '投影位移',
    bhfw: '闭合方位',
    gjm: '工具面',
    zxl: '造斜率',
    bdmc: '靶点名称',
    bklx: '靶框类型',
    ybbj: '圆靶半径',
    jxbbg: '矩形靶半高',
    jxbbk: '矩形靶半宽',
    jsjgxx: '井身结构信息', // title
    kzcx: '开钻次序',
    ztcc: '钻头尺寸',
    tgcc: '套管尺寸',
    tgxs: '套管下深',
    tglx: '套管类型',
    tggj: '套管纲级',
    lxmc: '类型名称',
    tgds: '套管顶深',
    zsnfs: '注水泥方式',
    snfgdd: '水泥反高度',
    snfgd: '水泥封固段',
    ztcc2: '钻头尺寸2',
    tgcc2: '套管尺寸2',
    zjzhxx: '钻具组合信息', // title
    zjzhbh: '钻具组合编号',
    qsjs: '起始井深',
    zzjs: '终止井深',
    jdjytms: '井段及用途描述',
    zjzh: '钻具组合',
    zjyxx: '钻井液信息', // title
    fdh: '分段号',
    zjylx: '钻井液类型',
    zjymdxx: '钻井液密度下限',
    zjymdsx: '钻井液密度上限',
    ldndxx: '漏斗黏度下限',
    ldndsx: '漏斗黏度上限',
    apilslxx: 'API滤失量下限',
    apilslsx: 'API滤失量上限',
    apilbhdxx: 'API滤饼厚度下限',
    apilbhdsx: 'API滤饼厚度上限',
    hthplslxx: 'HTHP滤失量下限',
    hthplslsx: 'HTHP滤失量上限',
    hthplbhdxx: 'HTHP滤饼厚度下限',
    hthplbhdsx: 'HTHP滤饼厚度上限',
    jql10mxx: '静切力10s下限',
    jql10msx: '静切力10s上限',
    jql10fxx: '静切力10min下限',
    jql10fsx: '静切力10min上限',
    sxndxx: '塑性黏度下限',
    sxndsx: '塑性黏度上限',
    dqlxx: '动切力下限',
    dqlsx: '动切力上限',
    lxzsxx: '流性指数下限',
    lxzssx: '流性指数上限',
    cdxsxx: '稠度系数下限',
    cdxssx: '稠度系数上限',
    ztsyxx: '钻头使用信息', // title
    xrjs: '下入井深',
    qcjs: '起出井深',
    ztxhtj1: '钻头型号推荐1',
    ztxhtj2: '钻头型号推荐2',
    ztxhtj3: '钻头型号推荐3',
    zyxx: '钻压下限',
    zysx: '钻压上限',
    zsxx: '转速下限',
    zssx: '转速上限',
    plxx: '排量下限',
    plsx: '排量上限',
    jxzsplus: '机械钻速+',
    jxzs: '机械钻速',
    ztyl: '钻头用量',
    pzzh: '喷嘴组合',
    gcjdxx: '工程进度信息', // title
    zjzyxm: '钻井作业项目',
    jhsgts: '计划施工天数',
    ljsgts: '累计施工天数',
    jdxx: '井队信息', // title
    zjgs: '钻井公司',
    zjdz: '钻井队长',
    zjdqtlxfs: '钻井队其它联系方式',
    zjcs: '钻机厂商',
    zjxh: '钻机型号',
    ljgs: '录井公司',
    ljdz: '录井队长',
    ljdqtlxfs: '录井队其它联系方式',
    ljysccs: '录井仪生产厂商',
    ljyxh: '录井仪型号',
    dxjgs: '定向井公司',
    dxjd: '定向井队',
    dxjdz: '定向井队长',
    dxjdxcdh: '定向井队现场电话',
    dxjdlxfs: '定向井队联系方式',
    rgjds: '人工井底深',
    xrsj: '下入时间',
    qcsj: '起出时间',
    zjdc: '钻进段长',
    sgnr: '施工内容',
    xjxh: '下井序号',
    ztxh: '钻头序号',
    ztxlh: '钻头序列号',
    ztxingh: '钻头型号',
    iadcbh: 'IADC编号',
    xjcs: '下井次数',
    ztzj: '钻头直径',
    ztlx: '钻头类型',
    sccj: '生产厂家',
    cjlb: '厂家类别',
    rjxd: '入井新度',
    cjxd: '出井新度',
    pzzj1: '1#喷嘴直径',
    pzlx1: '1#喷嘴类型',
    pzzj2: '2#喷嘴直径',
    pzlx2: '2#喷嘴类型',
    pzzj3: '3#喷嘴直径',
    pzlx3: '3#喷嘴类型',
    pzzj4: '4#喷嘴直径',
    pzlx4: '4#喷嘴类型',
    pzzj5: '5#喷嘴直径',
    pzlx5: '5#喷嘴类型',
    pzzj6: '6#喷嘴直径',
    pzlx6: '6#喷嘴类型',
    pzzj7: '7#喷嘴直径',
    pzlx7: '7#喷嘴类型',
    pzzj8: '8#喷嘴直径',
    pzlx8: '8#喷嘴类型',
    pzzj9: '9#喷嘴直径',
    pzlx9: '9#喷嘴类型',
    pzzj10: '10#喷嘴直径',
    pzlx10: '10#喷嘴类型',
    jc: '进尺',
    czjsj: '纯钻进时间',
    qxzsj: '起下钻时间',
    khysj: '扩划眼时间',
    ycmsqk: '牙齿磨损情况',
    zcmsqk: '轴承磨损情况',
    zjmsqk: '直径磨损情况',
    npcmsqk: '内排齿磨损情况',
    wpcmsqk: '外排齿磨损情况',
    qxjgmstz: '切削结构磨损特征',
    qxjgmswz: '切削结构磨损位置',
    zcmfmsqk: '轴承密封磨损情况',
    qzyy: '起钻原因',
    qtmstz: '其他磨损特征',
    tgmc: '套管名称',
    zy: '钻压',
    zs: '平均机械钻速',
    dlgjjzs: '动力工具及转速',
    pl: '排量',
    lgyl: '立管压力',
    md: '密度',
    ldnd: '漏斗黏度',
    hsl: '含砂量',
    hsl1: '含砂量1',
    apilsl: 'API滤失量',
    sxnd: '塑性黏度',
    dql: '动切力',
    ztyj: '钻头压降',
    hkyh: '环空压耗',
    slcjl: '射流冲击力',
    pssd: '喷射速度',
    ztsgl: '钻头水功率',
    bsgl: '比水功率',
    zgnzdsfsd: '钻杆内最低上返速度',
    ztnzdsfsd: '钻铤内最低上返速度',
    gllyl: '功率利用率',
    bgj1: '1#泵缸径',
    bcs1: '1#泵冲数',
    bgj2: '2#泵缸径',
    bcs2: '2#泵冲数',
    bgj3: '3#泵缸径',
    bcs3: '3#泵冲数',
    bgj4: '4#泵缸径',
    bcs4: '4#泵冲数',
    zgwj: '钻杆外径',
    ztwj: '钻铤外径',
    zjyqyxx: '钻井液取样信息', // title
    qysj: '取样时间',
    qyjs: '取样井深',
    ckwd: '出口温度',
    ph: 'pH值',
    jqls: '静切力10s',
    jqlmin: '静切力10min',
    apilbhd: 'API滤饼厚度',
    mzxs: '摩阻系数',
    xzndjds3: 'R₃',
    xzndjds6: 'R₆',
    xzndjds100: 'R₁₀₀',
    xzndjds200: 'R₂₀₀',
    xzndjds300: 'R₃₀₀',
    xzndjds600: 'R₆₀₀',
    hthplsl: 'HTHP滤失量',
    hthplbhd: 'HTHP滤饼厚度',
    hyl: '含油量',
    hsl2: '含水量',
    zxhl: '钻屑含量',
    zgxl: '总固相量',
    mbthl: 'MBT含量',
    llzhl: '氯离子含量',
    glzhl: '钙离子含量',
    jlzhl: '钾离子含量',
    zkhd: '总矿化度',
    dsb: '动塑比',
    lxzs: '流性指数',
    cdxs: '稠度系数',
    cw: '层位',
    yysb: '油盐水比',
    jd: '碱度',
    jingd: '井队',
    prdy: '破乳电压',
    gc: '国产',
    jk: '进口',
    deploymentName: '部署类别名称',
    deploymentYear: '部署年份',
    warnGroupTable: '预警组列表',
    warnUp: '故障概率上限',
    warnDown: '故障概率下限',
    warnLevel: '预警等级',
    seriesName: '模板名称',
    newSeries: '新增模板',
    seriesApplication: '模板应用',
    noticeTypeName: '通知类型',
    warnGroup: '通知人员',
    fssj: '预警时间',
    gk: '工况',
    fxmc: '风险名称',
    fsgl: '预警等级',
    zzjxxlb: '正钻井信息列表',
    completionTime: '完钻时间',
    qxrq: '取心日期',
    qxStartDate: '取心开始日期',
    qxEndDate: '取心起出日期',
    acquisitionRate: '获取率',
    coreDescription: '岩心描述',
    xc: '心长',
    qxl: '取心率',
    kc: '开次',
    dc: '地层',
    fscsj: '非生产时间',
    systemAlarmTime: '系统预警时间',
    alarmType: '预警类型',
    alarmContent: '预警内容',
    alarmLevel: '预警等级',
    handle: '处理状态',
    handleTime: '处理时间',
    personHandling: '处理人',
    actionsProcessed: '处理动作',
    associatedUsers: '关联用户',
    alarmProcessingHistory: '预警处理历史',
    otherWellSelect: '选择邻井',
    NoOtherWellSelect: '暂无邻井',
    csbs: '钻头使用次数标识',
    zkycrq: '钻开油层日期',
    wzcjrq: '完钻测井日期',
    jbmx: '井别明细',
    jxmx: '井型明细',
    cbfs: '承包方式',
    bqrq: '搬迁日期',
    azrq: '安装日期',
    kzsj: '开钻时间',
    kzsj1: '第一次开钻时间',
    wzsj1: '第一次完钻时间',
    kzsj2: '第二次开钻时间',
    wzsj2: '第二次完钻时间',
    kzsj3: '第三次开钻时间',
    wzsj3: '第三次完钻时间',
    kzsj4: '第四次开钻时间',
    wzsj4: '第四次完钻时间',
    kzsj5: '第五次开钻时间',
    wzsj5: '第五次完钻时间',
    kzsj6: '第六次开钻时间',
    wzsj6: '第六次完钻时间',
    kzsj7: '第七次开钻时间',
    wzsj7: '第七次完钻时间',
    kzsj8: '第八次开钻时间',
    wzsj8: '第八次完钻时间',
    wjsj: '完井时间',
    jjsj: '交井时间',
    zqsj: '钻前时间',
    wjzq: '完井周期',
    ztcssj: '中途测试时间',
    zjzq: '钻井周期',
    jjzq: '建井周期',
    yxhtjc: '有效回填进尺',
    bjfgjc: '本井返工进尺',
    qxjc: '取心进尺',
    zjjcssl: '钻井进尺损失率',
    sjcw: '设计层位',
    ycdbjs1: '油层顶部井深',
    ycdbjs2: '油层底部井深',
    yxcd: '岩心长度',
    yxshl: '岩心收获率',
    pjdtjc: '取心平均单筒进尺',
    wzxs: '完钻斜深',
    wzczjs: '完钻垂直井深',
    zjty: '钻机台月',
    tyxl: '台月效率',
    pjjxzs: '平均机械钻速',
    fzsj: '分支时间',
    czdtgcc: '侧钻点套管尺寸',
    czkzsj: '侧钻开钻时间',
    czwzsj: '侧钻完钻时间',
    czsj1: '第一次侧钻时间',
    czdjs1: '第一次侧钻点井深',
    czsj2: '第二次侧钻时间',
    czdjs2: '第二次侧钻点井深',
    tzjf: '调整井否',
    qjf: '气井否',
    qphf: '前平衡否',
    xjyf: '小井眼否',
    txr: '填写人',
    jsfzr: '技术负责人',
    scr: '审查人',
    tbdw: '填报单位',
    gzmc: '构造名称',
    cxwz: '测线位置',
    hssd: '海水深度',
    lrsd: '联入深度',
    bxhb: '补心海拔',
    ptbh: '平台编号',
    jd1: '经度',
    wd: '纬度',
    wzcw: '完钻层位',
    gjkssj1: '第一次固井开始时间',
    gjjssj1: '第一次固井结束时间',
    gjkssj2: '第二次固井开始时间',
    gjjssj2: '第二次固井结束时间',
    gjkssj3: '第三次固井开始时间',
    gjjssj3: '第三次固井结束时间',
    gjkssj4: '第四次固井开始时间',
    gjjssj4: '第四次固井结束时间',
    gjkssj5: '第五次固井开始时间',
    gjjssj5: '第五次固井结束时间',
    gjkssj6: '第六次固井开始时间',
    gjjssj6: '第六次固井结束时间',
    gjkssj7: '第七次固井开始时间',
    gjjssj7: '第七次固井结束时间',
    gjkssj8: '第八次固井开始时间',
    gjjssj8: '第八次固井结束时间',
    zjxh1: '钻具序号',
    zjmc: '钻具名称',
    zjsx: '钻具缩写',
    cd: '长度',
    wj: '外径',
    nj: '内径',
    bh: '壁厚',
    zggj: '钻杆钢级',
    zgxjcd: '钻杆新旧级别',
    gczl: '公称重量',
    qfqd: '屈服强度',
    klqd: '抗拉强度',
    knqd: '抗扭强度',
    kjqd: '抗挤强度',
    knyqd: '抗内压强度',
    fdzl: '分段重量',
    ljzl: '累计重量',
    fdzdzxl: '分段最大轴向力',
    fdzdwqyl: '分段最大玩去应力',
    fdzdnj: '分段最大扭矩',
    kjaqxs: '抗挤安全系数',
    jtklaqxs: '静态抗拉安全系数',
    zjklaqxs: '钻进抗拉安全系数',
    knaqxs: '抗扭安全系数',
    misesxs: 'MISES安全系数',
    zjsl: '钻具数量',
    DGZJCD: '单根钻具长度',
    lrsj: '录入时间',
    gxrq: '更新日期',
    cs: '垂深',
    jsff: '计算方法',
    gmz: '伽马值',
    recordType: '预警方式',
    byxx: '泵压下限',
    bysx: '泵压上限',
    czsj: '纯钻时间',
    noFarAway: '未远传',
    farAway: '已远传',
    latestProcessingTime: '最新处理时间',
    shortcutGroupName: '快捷组名称',
    zjTime: '钻井时间',
    dcsd: '地层深度',
    alarmSwitch: '报警开关',
    designParamsMsg: '黄色显示内容为设计数据',
    mudSheet: {
      mudColumn: '钻井液参数',
      value: '数值',
      time: '时间',
      t1: '温度1',
      t2: '温度2',
      fv: '漏斗黏度',
      n: '流性指数',
      s: '硫离子浓度',
      k: '稠度指数',
      mwi: '入口密度',
      yp: '动切力',
      mg: '镁离子浓度',
      av: '表观黏度',
      pv: '塑性黏度',
      ph: 'pH值',
      cl: '氯离子浓度',
      ca: '钙离子浓度',
      mwo: '出口密度'
    },
    kgrq: '开工日期',
    wgrq: '完工日期',
    jhkgrq: '计划开工日期',
    jhwgrq: '计划完工日期',
    sgxx: '施工信息'
  },
  drillState: { // 钻井状态
    finish: '完钻',
    doing: '正钻',
    waiting: '待钻',
    completion: '完井中',
    wellCompletion: '完井结束',
    testing: '试油中',
    starting: '试油开始',
    EndOfOilTest: '试油结束'
  },
  pushState: {
    yes: '推送中',
    no: '中断'
  },
  activate: { // 激活
    yes: '是',
    no: '否'
  },
  JB: { // 井別
    straight: '直井',
    directional: '定向井'
  },
  JX: {
    explore: '探井',
    exploit: '开发井'
  },
  title: {
    addPerson: '新增人员',
    selectedPerson: '已选人员列表',
    addWell: '新增井',
    selectedWell: '已选井列表'
  },
  complex: {
    sglx: '复杂故障类型',
    cw: '层位',
    jz: '基准',
    alcx: '案例查询',
    alfx: '案例分析'
  },
  RtLoggingEntity: {
    depth: '钻达井深',
    tvb: '垂深',
    lagdepth: '迟到井深',
    bitdep: '钻头位置',
    blockpos: '大钩高度',
    timedistInst: '钻时',
    rpm: '转速',
    wob: '钻压',
    hkload: '大钩载荷',
    spp: '立管压力',
    cup: '套管压力',
    torque: '扭矩',
    spm1: '1号泵冲',
    spm2: '2号泵冲',
    spm3: '3号泵冲',
    pit1: '池体积1',
    pit2: '池体积2',
    pit3: '池体积3',
    pit4: '池体积4',
    pit5: '池体积5',
    pit6: '池体积6',
    pit7: '池体积7',
    pit8: '池体积8',
    tpit: '总池体积',
    mti: '入口温度',
    mto: '出口温度',
    mci: '入口电导',
    mco: '出口电导',
    mwi: '入口密度',
    mwo: '出口密度',
    mfi: '入口流量',
    flowout: '出口流量',
    // tdrpm: '顶驱转速',
    // tdtorque: '顶驱扭矩',
    zbc: '总泵冲',
    sjjs: '设计井深',
    rjc: '日进尺',
    zrjc: '昨日进尺',
    wellno: '井号',
    wellBoreNo: '井筒号',
    time: '时间',
    dcexp: 'DC指数',
    gk: '工况',
    zjzt: '钻井状态',
    gas: '总烃',
    c1: '甲烷',
    c2: '乙烷',
    c3: '丙烷',
    ic4: '正丁烷',
    nc4: '异丁烷',
    ic5: '正戊烷',
    nc5: '异戊烷',
    co2: '二氧化碳',
    h2: '氢气',
    h2s1: '硫化氢1',
    h2s2: '硫化氢2',
    h2s3: '硫化氢3',
    h2s4: '硫化氢4',
    casingShoe: '套管鞋ECD',
    bottomHole: '井底ECD',
    CasingShoePressure: '套管鞋地层压力',
    BottomHolePressure: '井底地层压力',
    wobBit: '钻头钻压',
    torqueBit: '钻头扭矩',
    maxFf: '最大摩阻',
    ztjxbn: '钻头机械比能',
    CuttingsConce: '岩屑浓度',
    pressureG: '环空ECD',
    Inc: '井斜角',
    depth_caseShoe: '套管鞋深度',
    axialForce: '轴向力',
    frictionalResistance: '摩阻',
    groundRpm: '地面转速',
    bitRpm: '钻头转速',
    groundTorque: '地面扭矩',
    bitTorque: '钻头扭矩',
    dmjxbn: '地面MSE',
    drillSpeed: '钻速',
    syymgd: '水眼液面高度',
    hkymgd: '环空液面高度',
  },
  sys: {
    name: '名称',
    icon: '图标',
    parent: '父级',
    level: '等级',
    multilingual: '前端国际化',
    resourcePath: '前端资源路径',
    componentPath: '前端组件路径',
    sort: '排序',
    grade: '级别',
    describe: '描述',
    account: '登录账号',
    password: '登录密码',
    Confirmpassword: '确认密码',
    nickname: '用户昵称',
    realname: '真实姓名',
    IDcard: '身份证号',
    tel: '联系电话',
    mail: '电子邮箱',
    depName: '部门名称',
    company: '所属单位',
    sinopecAuthenticatedUser: '认证用户',
    title: '职务',
    country: '国籍',
    transferstatus: '借调状态',
    status: '认证状态',
    registerNew: '认证',
    registerType: '注册方式',
    userGroup: '用户组',
    workGroup: '工作组',
    rolename: '名称',
    rolecode: '编码',
    code: '编码',
    userName: '用户名称',
    userCode: '用户组编码',
    Permission: '权限名称',
    permission: '权限',
    usergroupName: '用户组名称',
    videoRoomName: '会议室名称',
    essentialInformation: '基本信息',
    changePassword: '修改密码',
    originalPassword: '原密码',
    newPassword: '新密码',
    functionName: '功能名称',
    functionParentName: '父节点名称',
    functionCode: '功能代码',
    functionUsage: '功能使用描述',
    organization: '组织机构名称',
    organizationParent: '父组织机构',
    organizationType: '组织机构类型',
    organizationRoot: '根组织机构',
    organizationAlias: '组织机构别名',
    moduleName: '模块名称',
    rolesList: '角色列表',
    batchAudit: '批量审核',
    batchAssociatedRoles: '批量关联角色',
    pushOrNot: '是否已录井远传',
    pushOrNotS: '是否录井远传',
    istransitFrac: '是否压裂远传',
    istransitTesting: '是否试油远传',
    istransitPrectl: '是否控压远传'
  },
  time: {
    second: '秒',
    minutes: '分'
  },
  Length: {
    Meter: 'm',
    Foot: '英尺'
  },
  Logging: {
    DEVI: '井斜',
    HAZI: '井斜方位',
    AC: '声波时差',
    CAL: '井径',
    SP: '自然电位',
    RD: '深感应电阻率',
    bit: '钻头尺寸',
    DEN: '地层密度',
    GR: '自然伽马'
  },
  Velocity: {
    MinutePerMeter: '分钟/米',
    MinutePerFoot: '分钟/英尺'
  },
  RotationSpeed: {
    RadianPerMinute: '弧度/分钟'
  },
  Pressure: {
    Megapascal: '兆帕',
    PoundforcePerSquareFeet: '磅平方英尺'
  },
  Force: {
    Kilonewton: '千牛',
    Kilopoundforce: '千磅推力'
  },
  Energy: {
    KilonewtonMeter: '千牛.米',
    KilopoundforceFoot: '千磅推力.英尺'
  },
  Frequency: {
    PerMinute: '1/分'
  },
  Transmissibility: {
    CubicMeter: '立方米',
    CubicFoot: '立方英尺'
  },
  Temperature: {
    DegreeCelsius: '摄氏度',
    DegreeFahrenheit: '华氏度'
  },
  Conductivity: {
    SiemensPerMeter: '西/米',
    SiemensPerFoot: '西/英尺'
  },
  PressureGradient: {
    GramPerCubicCentimeterEq: '克/立方厘米',
    PoundPerCubicFootEq: '磅/立方英尺'
  },
  VolumetricFlowRate: {
    LiterPerSecond: '升/秒',
    CubicFootPerSecond: '立方英尺/秒'
  },
  ProcDrillcrewEntity: {
    ljd: '录井队',
    ljdxcdh: '录井队电话',
    zjd: '钻井队',
    zjdxcdh: '钻井队电话'
  },
  DeBasicEntity: {
    sjjs: '设计井深',
    sjcs: '设计垂深',
    dmhb: '地面海拔',
    bxhb: '补心海拔',
    jkzzb: '井口纵坐标',
    jkhzb: '井口横坐标',
    jdhzb: '井底横坐标',
    jdzzb: '井底纵坐标',
    jkjd: '井口经度',
    jkwd: '井口纬度',
    cpj: '磁偏角', // 磁偏角(°)
    cqj: '磁倾角', // 磁倾角(°)
    ccqd: '磁场强度', // 磁场强度(μT)
    fwxzj: '方位修正角', // 方位修正角(°)
    fzdjs: '分支点井深', // 分支点井深(m)
    czdjs: '侧钻点井深' // 侧钻点井深(m)
  },
  DeBha: {
    qsjs: '起始井深', // 起始井深(m)
    zzjs: '终止井深', // 终止井深(m)
    ztcc: '钻头尺寸' // 钻头尺寸(mm)
  },
  DeBit: {
    ztcc: '钻头尺寸', // 钻头尺寸(mm)
    xrjs: '下入井深', // 下入井深(m)
    qcjs: '起出井深', // 起出井深(m)
    pzzh: '喷嘴组合', // 喷嘴组合(mm)
    zyxx: '钻压下限', // 钻压下限(kN)
    zysx: '钻压上限', // 钻压上限(kN)
    zsxx: '转速下限', // 转速下限(r/min)
    zssx: '转速上限', // 转速上限(r/min)
    plxx: '排量下限', // 排量下限(L/s)
    plsx: '排量上限', // 排量上限(L/s)
    jxzs: '机械钻速' // 机械钻速+(m/h)
  },
  DeFluid: {
    qsjs: '起始井深', // 起始井深(m)
    zzjs: '终止井深', // 终止井深(m)
    ldndxx: '漏斗黏度下限', // 漏斗黏度下限(s)
    ldndsx: '漏斗黏度上限', // 漏斗黏度上限(s)
    apilslxx: 'API滤失量下限', // API滤失量下限(mL)
    hthplslxx: 'HTHP滤失量下限', // HTHP滤失量下限(mL)
    hthplslsx: 'HTHP滤失量上限', // HTHP滤失量上限(mL)
    jql10mxx: '静切力10s下限', // 静切力10s下限(Pa)
    jql10msx: '静切力10s上限', // 静切力10s上限(Pa)
    jql10fxx: '静切力10min下限', // 静切力10min下限(Pa)
    jql10fsx: '静切力10min上限', // 静切力10min上限(Pa)
    sxndxx: '塑性黏度下限', // 塑性黏度下限(mPa•s)
    sxndsx: '塑性黏度上限', // 塑性黏度上限(mPa•s)
    dqlxx: '动切力下限', // 动切力下限(Pa)
    dqlsx: '动切力上限', // 动切力上限(Pa)
    phxx: 'pH值下限', // pH值下限
    phsx: 'pH值上限', // pH值上限
    cdxsxx: '稠度系数下限', // 稠度系数下限(Pa•s)
    cdxssx: '稠度系数上限', // 稠度系数上限(Pa•s)
    apilslsx: 'API滤失量上限' // API滤失量上限(mL)
  },
  DeSchedule: {
    zzjs: '终止井深', // 终止井深(m)
    qsjs: '起始井深', // 起始井深(m)
    apilsl: '钻井液API滤失量', // 钻井液API滤失量
    hthplsl: 'HTHP滤失量', // 钻井液高温高压滤失量
    hsl: '钻井液含砂量', // 钻井液含砂量
    mbthl: '钻井液坂土含量', // 钻井液坂土含量
    glzhl: '钻井液钙离子含量', // 钻井液钙离子含量
    sxnd: '钻井液塑性黏度', // 钻井液塑性黏度
    qyjs: '钻井液取样井深', // 钻井液取样井深
    ldnd: '钻井液漏斗黏度', // 钻井液漏斗黏度
    jqls: '钻井液10s静切力', // 钻井液10s静切力
    apilbhd: '钻井液API滤饼厚度', // 钻井液API滤饼厚度
    hthplbhd: 'HTHP滤饼厚度', // 钻井液高温高压滤饼厚度
    zxhl: '钻井液钻屑含量', // 钻井液钻屑含量
    llzhl: '钻井液氯离子含量', // 钻井液氯离子含量
    jlzhl: '钾离子含量', // 钾离子含量
    zkhd: '钻井液总矿化度', // 钻井液总矿化度
    dql: '钻井液动切力', // 钻井液动切力
    cdxs: '钻井液稠度系数k值', // 钻井液稠度系数k值
    ckwd: '钻井液出口温度', // 钻井液出口温度
    jqlmin: '10min静切力', // 10s静切力
    hyl: '钻井液含油量', // 钻井液含油量
    zgxl: '钻井液总固相量', // 钻井液总固相量
    dsb: '钻井液动塑比', // 钻井液动塑比
    prdy: '油基钻井液' // 油基钻井液
  },
  DeStruct: {
    ztcc: '钻头尺寸', // 钻头尺寸(mm)
    js: '井深', // 井深(m)
    tgcc: '套管尺寸', // 套管尺寸(mm)
    rgjds: '人工井底深', // 人工井底深(mm)
    tgds: '套管顶深', // 套管顶深(m)
    tgxs: '套管下深', // 套管下深(m)
    tgcc2: '套管尺寸2', // 套管尺寸2(mm)
    ztcc2: '钻头尺寸2' // 钻头尺寸2(mm)
  },
  DeWellpath: {
    js: '井深', // 井深(m)
    cs: '垂深', // 垂深(m)
    jxj: '井斜角', // 井斜角(°)
    fwj: '方位角', // 方位角(°)
    nzb: 'N坐标', // N坐标(m)
    ezb: 'E坐标', // E坐标(m)
    bhj: '闭合距', // 闭合距(m)
    qjbhl: '全角变化率', // 全角变化率(°/m)
    jxbhl: '井斜变化率', // 井斜变化率(°/m)
    fwbhl: '方位变化率', // 方位变化率(°/m)
    sptycd: '水平投影长度', // 水平投影长度(m)
    tywy: '投影位移', // 投影位移(m)
    bhfw: '闭合方位', // 闭合方位(°)
    zxl: '造斜率', // 造斜率(°/100m)
    gjm: '工具面', // 工具面(°)
    ybbj: '圆靶半径' // 圆靶半径(m)
  },
  ProcBha: {
    ztcc: '钻头尺寸', // 钻头尺寸(mm)
    xrjs: '下入井深', // 下入井深(m)
    qcjs: '起出井深', // 起出井深(m)
    zjdc: '钻进段长' // 钻进段长(m)
  },
  ProcBit: {
    ztzj: '钻头直径',
    xrjs: '下入井深',
    qcjs: '起出井深',
    pzzj1: '1#喷嘴直径',
    rjxd: '入井新度',
    cjxd: '出井新度',
    pzzj3: '3#喷嘴直径',
    pzzj2: '2#喷嘴直径',
    pzzj4: '4#喷嘴直径',
    pzzj5: '5#喷嘴直径',
    pzzj6: '6#喷嘴直径',
    pzzj7: '7#喷嘴直径',
    pzzj9: '9#喷嘴直径',
    pzzj8: '8#喷嘴直径',
    pzzj10: '10#喷嘴直径',
    jxzs: '机械钻速',
    jc: '进尺',
    khysj: '扩划眼时间',
    czjsj: '纯钻进时间',
    qxzsj: '起下钻时间',
    ztwj: '钻铤外径',
    zgwj: '钻杆外径',
    zs: '转速',
    zy: '钻压',
    lgyl: '立管压力',
    pl: '排量',
    hsl: '含砂量',
    md: '密度',
    ldnd: '漏斗黏度',
    dql: '动切力',
    sxnd: '塑性黏度',
    apilsl: 'API滤失量',
    slcjl: '射流冲击力',
    ztyj: '钻头压降',
    hkyh: '环空压耗',
    ztsgl: '钻头水功率',
    pssd: '喷射速度',
    bsgl: '比水功率',
    gllyl: '功率利用率',
    bgj2: '2#泵缸径',
    bgj1: '1#泵缸径',
    bcs1: '1#泵冲数',
    bcs3: '3#泵冲数',
    bcs2: '2#泵冲数',
    bgj3: '3#泵缸径',
    bgj4: '4#泵缸径',
    bcs4: '4#泵冲数'
  },
  ProcStructEntity: {
    ztcc: '钻头尺寸',
    js: '井深',
    tgcc: '套管尺寸',
    tgxs: '套管下深',
    tgcc2: '套管尺寸2',
    tgds: '套管顶深',
    rgjds: '人工井底深',
    ztcc2: '钻头尺寸2'
  },
  ProcFluidSample: {
    qyjs: '取样井深',
    ckwd: '出口温度',
    md: '密度',
    ldnd: '漏斗黏度',
    hsl: '含砂量',
    jqls: '静切力10s',
    jqlmin: '静切力10min',
    apilsl: 'API滤失量',
    apilbhd: 'API滤饼厚度',
    mzxs: '摩阻系数',
    hthplsl: 'HTHP滤失量',
    hthplbhd: 'HTHP滤饼厚度',
    hyl: '含油量',
    hsl2: '含水量',
    zxhl: '钻屑含量',
    zgxl: '总固相量',
    mbthl: 'MBT含量',
    llzhl: '氯离子含量',
    glzhl: '钙离子含量',
    jlzhl: '钾离子含量',
    zkhd: '总矿化度',
    sxnd: '塑性黏度',
    dql: '动切力',
    dsb: '动塑比',
    cdxs: '稠度系数',
    prdy: '破乳电压'
  },
  ProcDailyrpt: {
    js: '当日井深',
    jc: '当日进尺',
    zjymd: '密度',
    zjyldnd: '漏斗黏度',
    zjysxnd: '塑性黏度',
    zjyhsl: '含砂量',
    zjyapilsl: 'API滤失量',
    zjydql: '动切力',
    zjymbthl: 'MBT含量',
    zy: '钻压',
    pl: '排量',
    zs: '转速',
    bc: '泵冲',
    by: '泵压',
    zjcs: '钻井参数',
    njcs: '泥浆参数',
    zjzh: '钻具组合',
    dlgj: '动力工具',
    scxxqk: '生产详细情况',
    xybsgap: '下一步施工安排',
    shr: '审核人',
    drgk: '当日工况',
    zydz: '钻压低值',
    zygz: '钻压高值',
    bya: '泵压',
    rwlx: '任务类型',
    jdqk: '监督情况',
    xmbjjdry: '项目部及监督人员',
    gtfqwczxx: '固体废弃物处置信息'
  },
  lwd: {
    js: '井深',
    jztGama: '近钻头伽马',
    jztGamaU: '近钻头上伽马',
    jztGamaD: '近钻头下伽马',
    jztGamaL: '近钻头左伽马',
    jztGamaR: '近钻头右伽马',
    ydGama: '远端伽马',
    ydGamaU: '远端上伽马',
    ydGamaD: '远端下伽马',
    ydGamaL: '远端左伽马',
    ydGamaR: '远端右伽马',
    jxjMwd: 'MWD井斜角',
    fwjMwd: 'MWD方位角',
    gjmMwdCx: 'MWD磁性工具面',
    gjmMwdZl: 'MWD重力具面'
  },
  smarthelmet: {
    remotePrompt: '远程提示',
    remoteMsg: '向您发起协助通话',
    hangUp: '挂断',
    answer: '接听'
  },
  ivf: {
    GR: '自然伽马',
    SP: '自然电位',
    BS: '钻头直径',
    LLD: '深侧向',
    LLS: '浅侧向',
    'AC': '声波时差',
    NPHI: '补偿中子',
    POTA: '钾',
    THOR: '钍',
    URAN: '铀',
    LL8: '八侧向',
    COND: '感应电导率',
    DEN: '岩石密度',
    ILM: '中感应',
    ILD: '深感应',
    '4m-RT': '4m梯度',
    '25mR': '2.5m梯度',
    SDXM: '最大水平主应力',
    SDYM: '最小水平主应力',
    PFM: '破裂压裂',
    YMOD: '杨氏模量',
    VSH: '泥质含量',
    POR: '孔隙度',
    POG: '垂向应力梯度（上覆压力梯度）',
    POM:	'垂向应力（上覆压力）',
    SDX:	'水平最大主应力梯度',
    SDY:	'水平最小主应力梯度',
    PFG:	'破裂压力梯度',
    PCG:	'坍塌压力梯度',
    PAZ:	'水平最大主应力方向',
    POIS:	'泊松比',
    ARFA:	'毕奥特系数',
    CDEN:	'计算密度',
    TS: '计算横波',
    DSR: '纵，横波速度比',
    BULK:	'出砂指数',
    TXML:	'弹性模量',
    SMOD:	'切变模量',
    BMOD:	'体积弹性模量',
    CB: '体积压缩模量',
    UR: '单轴抗压强度',
    TOURTOUR:	'固有剪切强度',
    ST: '抗压强度',
    PMX: '破裂压力当量密度',
    PMY: '坍塌压力当量密度',
    PMC: '钻井液密度',
    RESS:	'剩余剪应力',
    FII: '岩石破裂率',
    KV: '完整性系数',
    RF: '裂缝发育程度',
    RG: '岩石稳定系数',
    BRYP:	'脆性指数',
    DTC: '有机碳含量',
    K: '应力差异系数',
    CZDCZD: '（斜井）计算垂深'
  }
}
