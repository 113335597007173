export default {
  btn: {
    scmy: 'Generating key',
    empty: 'Empty',
    removeConcerns: 'Remove concerns',
    range: 'Instrument range',
    threshold: 'Warning threshold',
    setting: 'Parameter setting',
    applymodify: 'Apply modify',
    preview: 'Online preview',
    add: 'Add',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    reply: 'Reply',
    login: 'Log in',
    loginByNameAndPas: 'Login by name and PW',
    register: 'Register account',
    pleaseEnter: 'Please Enter',
    pleaseChoose: 'Please Select',
    pleaseEnterMsg: 'Please Enter {msg}',
    pleaseChooseMsg: 'Please Select {msg}',
    query: 'Query',
    search: 'Search',
    refresh: 'Refresh',
    new: 'Add',
    edit: 'Edit',
    change: 'Change',
    save: 'Save',
    No: 'No',
    personSexandID: 'Name and ID',
    preciseQuery: 'Precise query',
    remove: 'Remove',
    append: 'Append',
    sure: 'Sure',
    curve: 'Curve',
    data: 'Data',
    exportData: 'Data export',
    chooseTime: 'ChooseTime',
    reset: 'Reset',
    resetPassword: 'Reset PW',
    Disable: 'Disable',
    Enable: 'Enable',
    associatedRoles: 'Associated roles',
    Tips: 'Tips',
    showMenuTree: 'Show MenuTree',
    FuzzyUserName: 'Personnel fuzzy retrieval',
    FuzzySCodeorname: 'Fuzzy retrieval code or name',
    FuzzySname: 'Fuzzy retrieval name',
    FuzzyAccount: 'Fuzzy account retrieval',
    FuzzyGroupName: 'Fuzzy group Name',
    orgnizationName: 'Fuzzy orgnization retrieval',
    FuzzyJth: 'Fuzzy retrieval wellBoreId',
    keywordFilter: 'Keyword filter',
    Associatpermission: 'Associat permission',
    AssociatMenu: 'Associat menu',
    AssociatResources: 'Associat resources',
    Unassociatedmenu: 'Unassociated menu',
    associatedmenu: 'Associated menu',
    wellNoSearch: 'WellNo.Search',
    backPortal: 'Back Portal',
    userHandbook: 'User Handbook',
    UserAuthorization: 'UserAUTH',
    VideoRoomEnable: 'Enable',
    VideoRoomForbidden: 'Forbidden',
    VideoRoomEnter: 'Enter',
    clientDownLoad: 'ClientDownLoad',
    dailyUpload: 'Appendix upload',
    appendix: 'Appendix',
    appendixDownLoad: 'Appendix downLoad',
    appendixDelete: 'Appendix delete',
    examine: 'Examine',
    send: 'Send',
    saveAndSend: 'Save and send',
    forbidden: 'Forbidden',
    risk: 'Risk warning',
    WindowsDownload: 'Windows Download',
    macAppleDownload: 'Mac-Apple Download',
    received: 'Received',
    sent: 'Sent',
    focusOnWell: 'Focus on well',
    showWellLog: 'Search fullwell curve',
    deploy: 'Deploy',
    constructionRecord: 'While drilling construction record',
    mudLog: 'Mud record',
    jdSearch: 'Well team search',
    qxrqSearch: 'Coring search',
    contrastWell: 'Contrast multi wells',
    menu: 'Navigation menu',
    import: 'Import',
    downloadTemplate: 'Download template ',
    exportFailedData: 'Export failed data',
    upload: 'Upload',
    download: 'Download',
    changeTimes: 'Modify time period',
    addMessage: 'Send msg',
    show: 'Show',
    hide: 'Hide',
    custom: 'Custom',
    change2: 'Change',
    modifyAlarmTime: 'Modify alarm time',
    batchPersonnelProcessing: 'Batch personal processing',
    viewProcessing: 'View processing',
    startCalculation: 'Start cal',
    endCalculation: 'Stop cal',
    resetHST: 'Initialize video conference',
    resetHelmet: 'Initialize smart helmet',
    initialize: 'initialize',
    functionAssociation: 'Function Association',
    relevantInformationQuery: 'Relevant info query',
    calculationLog: 'Calculation log',
    automaticRetrieval: 'Auto retrieval after input',
    artificialWarning: 'Artificial warning',
    feedback: 'Feedback',
    startDate: 'Start Date',
    endDate: 'End Date',
    wellNew: 'Real Data',
    savePdf: 'Save Pdf',
    addUsers: 'Add Users',
    deleteUsers: 'Delete Users',
    importDesign: 'Import design threshold',
    buildUnit: 'Build Unit',
    constructionUnit: 'Construction Unit',
    kaizuan: 'Drilling',
    daizuan: 'To be drilled',
    visibleUnits: 'Visible units'
  },
  tiles: {
    singleWellQuery: 'Single well info query',
    multiInfoCompare: 'Multiwell info comparison',
    wellTracking: 'Well tracking',
    regionalComplexStatistics: 'Regional complex statistics',
    StaticDataEntry: 'Data mgt',
    videoMeeting: 'Video meeting',
    videoSurveillance: 'Video surveillance',
    fracturingTracking: 'Fracturing tracking and analysis',
    systemManagement: 'System mgt',
    resultDocQuery: 'Result document query',
    search: 'Senior search',
    XBJ: 'Well drilling info integration-GEO info navigation',
    addUser: 'Add user',
    editUser: 'EditUser',
    examineUser: 'examineUser',
    fracturing: 'Acidizing Fracturing Data mgt and Analysis System',
    wellInfo: 'Well info',
    GeographicInfo: 'Geoinfo',
    relatedInfo: 'Related wellinfo',
    InputInfo: 'Input info',
    wellpath: 'Well path design scattered point',
    addVideoRoom: 'AddVideoRoom',
    editVideoRoom: 'EditVideoRoom',
    addWarnLevel: 'Add fault level',
    editWarnLevel: 'Edit fault level',
    addwarnConfig: 'Add warn config',
    editwarnConfig: 'Edit warn config',
    PetroleumGasTestMonitoring: 'PetroleumGasTestMonitoring',
    Integrateddisplay: 'Well drilling info analyze',
    comingSoon: 'Coming soon',
    editMessage: 'Edit msg',
    addMessage: 'Add message',
    videoRoomCode: 'Video Room Code',
    videoRoomName: 'Video Room Name',
    videoRoomStatus: 'Video Room Status',
    complexPromptOfAdjacentWell: 'Complex prompt of adjacent well',
    noComplexPromptOfAdjacentWell: 'No complex prompt of adjacent well',
    dealWith: 'Deal with',
    changeAlarmLevel: 'Change alarm level',
    selectedUsers: 'Selected users',
    associateMoreUsers: 'Associate more users',
    szjcsj: 'Basic drilling data',
    szjygj: 'Well drilling  trajectory',
    szzjmx: 'Drilling tool details'
  },
  navbar: {
    language: 'Language',
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    screenfull: 'Screenfull',
    personal: 'Personal center',
    theme: 'Theme',
    dimension: 'Dimension'
  },
  login: {
    title: 'system login',
    logIn: 'Log in',
    username: 'Username',
    sinopecAuth: 'Auth login',
    password: 'Pw',
    userLogin: 'User login',
    verificationCode: 'Verification Code',
    autoLogin: 'Auto log in',
    forgetPassword: 'Forgot pw',
    signUp: 'Registered account',
    pleaseEnterVerificationCode: 'Please Enter Verification Code',
    verificationCodeError: 'Verification Code Error',
    passLogin: 'Accountand pw login'
  },
  user: {
    name: 'Name',
    account: 'Account',
    company: 'Company',
    status: 'Status',
    lastupdated: 'Last updated',
    title: 'Title',
    content: 'Content',
    sendDate: 'Send date',
    receiveUserName: 'Receiver',
    statusName: 'Status',
    sendUser: 'Sender',
    msg: 'Msg',
    receiveUser: 'Response user',
    receiveDate: 'Response time',
    read: 'Read',
    noRead: 'Unread',
    send: 'Sent',
    save: 'Saved',
    checkMsg: 'Select the msg to view'
  },
  permission: {
    roles: 'Your roles',
    switchRoles: 'Switch roles'
  },
  errorLog: {},
  well: {
    time: 'Time',
    percent: 'Percent',
    jtNum: 'WellBore No.',
    color: 'Color',
    al: 'Alarm record',
    cd: 'Curve show',
    dd: 'Data show',
    vhd: 'History data check',
    road: 'Log',
    number: 'Well No list',
    numberList: 'WellBore No. list',
    currentWorkingCondition: 'Current working condition',
    hover: 'Hover',
    nowTime: 'Current time',
    sunbei53X: 'sunbei53X well',
    sunbei63X: 'sunbei63X well',
    sunnan53X: 'sunnan53X well',
    DesignWellDepth: 'Design well-depth',
    CurrentDepth: 'Current depth',
    BITDepthM: 'BIT Depth(m)',
    RealtimeDrillingInformation: 'RDinfo',
    WellDepth: 'Well depth',
    drillingBitPosition: 'DB position',
    drillingBitPositionM: 'DB position(m)',
    BITDepth: 'BIT depth',
    TurntableTorque: 'Turntable torque(kn.m)',
    Torque: 'Turntable torque',
    WaterParameter: 'Water/GL parameter',
    FLWIN: 'FLW IN(L/s)',
    FLWIN1: 'FLW IN',
    FLWOUT: 'FLW OUT(%)',
    FLWOUT1: 'FLW OUT',
    SUM1: 'SUM1(m³)',
    SUM: 'SUM1',
    TemperatureParameter: 'Tmp parameter',
    showData: 'Show data',
    DQNJ: 'Torque(kN·m)',
    TMPIN: 'TMP IN',
    TMPOUT: 'TMP OUT',
    GasLoggingParameters: 'GL Parameters',
    CalculationParamter: 'Cal Paramter',
    PoolVolumeParameters: 'PV parameters',
    TGASma: 'T GAS ma(%)',
    TGASma1: 'T GAS ma',
    CollectionTime: 'Collection Time',
    StandardWellDepth: 'Standerd wellDepth(m)',
    TVDDepth: 'TVD Depth(m)',
    LAGDepth: 'LAG Depth(m)',
    HKHGHT: 'Hook position',
    WOH: 'WOH(kN)',
    WOH1: 'WOH',
    WOB: 'WOB(kN)',
    RPM: 'RPM(r/min)',
    SPP: 'SPP(MPa)',
    spp: 'SPP',
    WHP: 'WHP(MPa)',
    ROP: 'ROP(min/m)',
    PIT1: 'PIT1(m³)',
    PIT2: 'PIT2(m³)',
    PIT3: 'PIT3(m³)',
    pit3: 'PIT3',
    PIT4: 'PIT4(m³)',
    pit4: 'PIT4',
    PIT5: 'PIT5(m³)',
    pit5: 'PIT5',
    PIT6: 'PIT6(m³)',
    pit6: 'PIT6',
    PUMP1: 'PUMP1(spm)',
    PUMP2: 'PUMP2(spm)',
    FLWPUMPS: 'Total pump count(spm)',
    MWIN: 'MW IN(q/cm³)',
    MWIN1: 'MW IN',
    EDensity: 'Entrance density',
    MWOUT: 'MW OUT(q/cm³)',
    MWOUT1: 'MW OUT',
    CONIN: 'CON IN(S/m)',
    CONOUT: 'CON OUT(S/m)',
    TMPINC: 'TMP IN(℃)',
    TMPOUTC: 'TMP OUT(℃)',
    C1main: 'C1(%)',
    C2main: 'C2(%)',
    C3main: 'C3(%)',
    nC4main: 'Nc4(%)',
    iC4main: 'Ic4(%)',
    nC5main: 'Nc5(%)',
    iC5main: 'Ic5(%)',
    h2: 'H2(%)',
    CO2gen: 'CO2(%)',
    H2S1Max: 'H2S1(%)',
    H2S2Max: 'H2S2(%)',
    RealtimeData: 'Realtime data',
    ListData: 'Parameter list',
    EngineeringParameter: 'Engineering parameter',
    SlurryParameter: 'Slurry parameter',
    DCexponential: 'DC exponential',
    DrillTorque: 'Drill torque',
    MechanicalSpecificEnergyofDrillBit: 'MSE of DB',
    BottomHole: 'Bottom hole ECD',
    BottomHolePressure: 'Bottom hole press',
    PureDrillingTimeRatio: 'Pure drilling time(Ratio)',
    CasingShoe: 'Casing shoe ECD',
    CasingShoePressure: 'CasingShoe Press',
    DrillingTimeRatio: 'Drilling Time(Ratio)',
    DrillingPressure: 'Drilling Press',
    VerticalWellDepth: 'Vertical Well Depth',
    RotatingSpeed: 'Rotating Speed',
    EntranceVolume: 'Entry Vol',
    ExportVolume: 'Export Vol',
    Hookheight: 'Hook height',
    Bitdepth: 'Bit depth',
    Welldepthdesign: 'Well depth design',
    logSetting: 'Log Setting',
    line1: 'Line one',
    line2: 'Line two',
    line3: 'Line three',
    line4: 'Line four',
    linename: 'Line name',
    min: 'Min',
    max: 'Max',
    unit: 'Unit',
    add: 'Add curve',
    currentDepth: 'Currentdepth',
    hide: 'Hidden',
    indexTrackHistory: 'Time',
    indexTrackMeter: 'Depth',
    event: 'Event',
    reason: 'Reason',
    rq: 'Date',
    visibleLimits: 'Visible time Limits',
    halfHour: 'Half hour',
    anHour: '	An hour',
    twoHours: 'Two hours',
    tvb: 'TVB',
    horizon: 'Drillhorizon',
    interruption: 'Data interruption',
    pushState: 'Push state',
    pureDrillingTime: 'Pure drilling time',
    nonPureDrillingTime: 'Non Pure drilling time',
    computingTime: 'Computing time',
    FullWellCurve: 'Full well curve',
    jxzsdb: 'Comparison of ROP',
    scsxdb: 'PTE comparison',
    ljfzxq: 'Complex details of AW',
    wellArea: 'Adjacent well area',
    timeInterval: 'Time interval(s)',
    frictionCoefficientSetting: 'Log Setting',
    settingOfWorkingCondition: 'Work condition setting',
    fullDataAutoRegression: 'Full data auto regr',
    regressionOfNodeData: 'Regrnode data',
    trendlineFunction: 'Trendline function',
    frictionCoefficientOfCasing: 'Friction coefficient casing',
    openEyeFrictionCefficient: 'Naked eye friction coefficient',
    coefficientSetting: 'Log Setting',
    slurry: 'Slurry',
    calculationOfHydraulicParameters: 'Hydr parameters cal',
    calculationOfDisplacementOfCementPlug: 'Displacement cement plug  cal ',
    workOrderOfCardReleasing: 'Work order card releasing',
    volumeOfCommonDrillingTools: 'Vol common drilling tools',
    makeUpTorqueOfDrillingTool: 'Make up torque drilling tool',
    casingMakeUpTorque: 'Casing make-up torque',
    predictiveParameters: 'Predictive parameters',
    circulatingPressureConsumption: 'Circulating press consumption',
    bitHydraulic: 'Bit hydr',
    stringPerformanceTable: 'Tubular col performance table',
    casingStrengthCheck: 'Casing pipe strength check',
    punchNumberOfCylinderLiner: 'cylinder liners punch no',
    comparisonTable: 'Comparison table of common steel ring bolts of well control device',
    md: 'Density',
    pl: 'Displacement',
    pressureDrop: 'Drilling tools\'internal press drop ',
    timeliness: 'Prescription',
    RealtimeMonitoring: 'Realtime Monitoring',
    daily: 'Daily',
    schedul: 'Construction schedul',
    szzjzhsyqksj: ' Drillling tool assembly usage record',
    szztsyqksj: 'Bit usage record',
    usageOfCoring: 'Usage of coring', // 取心使用情况
    zjyxnsjsj: 'Drilling fluid sampling record',
    historyData: 'Logging playback',
    ownspace: 'Own space',
    fdhStart: 'Starting section (layer) No',
    fdhEnd: 'Termination section (layer) No',
    sectionNo: 'Section(layer) No',
    waningValue: 'Oil pressure warning value',
    fracturingInformation: 'Fracturing Info',
    startTime: 'Start time',
    constructionTime: 'Construction time',
    cumulative: 'Cumulative',
    oilPressure: 'Oil pressure',
    casingPressure: 'Casing pressure',
    displacement: 'Displacement',
    totalLiquidVolume: 'Total Liquid Volume',
    sandRatio: 'Sand ratio',
    totalSand: 'Total sand',
    warningInfo: 'Warning Info'
  },
  msg: {
    addSuccess: 'Add success',
    addError: 'Add error',
    identity: 'Jumping',
    loginSuccess: 'Welcome!',
    loginError: 'Login Error!',
    sinopecAuth: 'Sinopec auth login',
    details: 'Details',
    pleaseChoose: 'Please choose',
    ComplexStatistics: 'Complex statistics',
    fnNo: '49 No.',
    ffNo: '55 No.',
    fsNo: '56 No.',
    foNo: '61 No.',
    DrillingDaily: 'Drilling daily',
    realtimeMonitor: 'Realtime monitor',
    DrillingSchedule: 'Drilling schedule',
    deleteSuccess: 'Delete success!',
    deleteFailed: 'Delete failed!',
    deleteUsedNot: 'This item has been used cannot be deleted!',
    notNull: 'Not allowed for null',
    deleteOrnot: 'Whether to delete?',
    deleteWell: 'Whether to delete?',
    forbidden: 'Whether to forbidden?',
    enterpasswordagain: 'Please enter your pw again!',
    twodonotmatch: 'The two pws do not match!',
    existsNoadd: 'Already exists, please do not repeat to add!',
    Lengthcharacters: 'Length at 6 to 16 characters!',
    LengthLimit: 'Length at 1 to 15 characters!',
    LengthLimit10: 'Length at 1 to 10 characters!',
    LengthLargeLimit: 'Length at 0 to 100 characters!',
    LengthDyncLimit: 'Length at {0} to {1} characters!',
    nochange: 'Data has no change',
    submitting: 'Submitting',
    successful: 'Successful',
    failed: 'Failed',
    SinopecNorthWest: 'Sinopec North-West',
    numberdecimal: 'Please enter a number!',
    numberInteger: 'Please enter a positive number!',
    number1decimal: 'Please enter a number, 1 decimal places at most!',
    number2decimal: 'Please enter a number, 2 decimal places at most!',
    number3decimal: 'Please enter a number, 3 decimal places at most!',
    number4decimal: 'Please enter a number, 4 decimal places at most!',
    realTimeLogwarn: 'Please use the "History Curve" function.',
    ThePastHour: 'The Past Hour',
    ThePastThreeHours: 'The Past Three Hours',
    PastDay: 'Past Day',
    PastThreeDays: 'Past Three Days',
    LastWeek: 'Last Week',
    customTime: 'Custom Time',
    loadingLog: 'Loading Last {msg}(min) Log Data',
    saveSuccess: 'Save success!',
    saveError: 'Save error!',
    upOverDown: 'Error',
    sendSuccess: 'Send success!',
    sendError: 'Send error',
    noData: 'No Data',
    noWarnMsg: 'No Warning Data',
    httpMsg: 'Data update to',
    updateError: 'Update error, please try again later!',
    updateOver: 'Update completed',
    dragFilesHere: 'Drag Files Here',
    clickUpload: 'Click Upload',
    onlyXlsx: 'Only xlsx files can be uploaded',
    dataExist: 'Duplicate data, please modify the data and try again!',
    chooseUser: 'Please select user',
    errorMsg: 'Service exception',
    calculationResults: 'Calculation results',
    feedbackPlaceholder: 'If you have any comments or suggestions in the use process, please leave your valuable comments or suggestions!',
    feedbackRule: 'Please input opinions or suggestions within 200 words!',
    feedbackSaveSuccess: 'Your proposal has been submitted for processing. Thank you for your valuable advice!',
    passwordLength: 'The length is between 8 and 16 characters.',
    validatePassword: 'Passwords must contain numbers, letters and characters.'
  },
  units: {
    M: 'M',
    KM: 'KM'
  },
  sheet: {
    gysmc: 'SupplierName',
    my: 'SecretKey',
    sjjs: 'Design well depth',
    num: 'Number',
    jsjg: 'whether well structure show?',
    thresholdSetting: 'Parameter thresholdSetting',
    stratumSetting: 'Stratum Setting',
    jth: 'WellboreNo',
    sgxq: 'Accident detail',
    sgfsjs: 'Accident depth',
    sgfzmc: 'Complex accident name',
    sgfzmx: 'Complex accident details',
    sgfssj: 'Event time',
    sgfscs: 'Number of accidents',
    sgjcsj: 'Remove time',
    js: 'Depth',
    ztjs: 'Bit depth',
    cwjyx: 'Horizon and rock character',
    fgjc: 'Rework footage',
    bfjc: 'Scrap footage',
    sgsssj: 'Losttime of accident',
    zjjjss: 'Direct economic loss',
    clgk: 'Handle survey',
    sszjcd: 'Lost drilling tool length',
    remarks: 'Remarks',
    createBy: 'CreateBy',
    cjr: 'CreateBy',
    createDate: 'CreateDate',
    updateBy: 'UpdateBy',
    updateDate: 'UpdateDate',
    delFlag: 'DelFlag',
    blockId: 'BlockId',
    blockName: 'BlockName',
    stratumName: 'FormationName',
    DCDH: 'FormationCode',
    rockName: 'RockName',
    topDepth: 'TopDepth',
    bottomDepth: 'BottomDepth',
    color: 'Rockcolor',
    details: 'Rock character details',
    progress: 'Rock well progress',
    all: 'All',
    detailed: 'Detailed info',
    list: 'List',
    icon: 'Icon',
    totalwell: 'Total well',
    company: 'Firm',
    xz: 'Hanging weight',
    styqdw: 'Tonnage of lift truck',
    xfyzdw: 'Down lift tonnage',
    workGroupSheet: 'WorkGroupSheet',
    personSheet: 'PersonSheet',
    wellSheet: 'WellSheet',
    groupname: 'Groupname',
    wellNo: 'WellNo',
    groupId: 'GroupId',
    groupName: 'GroupName',
    remark: 'Remarks',
    wellId: 'WellId',
    SSDW: 'Company',
    JSSYTDW: 'JSSYTDW',
    block: 'Block',
    JB: 'Well classfication',
    JX: 'Well type',
    ZJZT: 'Drilling state',
    SFJH: 'SFJH',
    GLDWID: 'GLDWID',
    SFFXJ:"SFFXJ",
    SSZTZTJS:"SSZTZTJS",
    groupManage: 'GroupManager',
    personnel: 'Personnel',
    organization: 'Organization',
    isGroupManage: 'IsGroupManager',
    operat: 'Operate',
    ZJD: 'Drilling team',
    ZJDXCDH: 'Drilling team phone',
    LJD: 'Logging team',
    LJDXCDH: 'Logging team phone',
    YJSJ: 'Warning time',
    GZMC: 'Fault name',
    FSJS: 'Occurrence depth',
    FSDC: 'Occurrence bottom',
    Curvename: 'Curve name',
    min: 'Min',
    max: 'Max',
    unit: 'Unit',
    CurrentDepth: 'Current depth',
    FirstReturn: 'Times',
    WellTeam: 'Drilling progress',
    paramsName: 'Params name',
    paramsMin: 'Params min',
    paramsMax: 'Params max',
    paramsWarn: 'Params warn',
    companyName: 'Company name',
    mdc: 'Purpose layer',
    sjcs: 'Design depth',
    zjmd: 'Drilling purpose',
    wzyz: 'Completion principle',
    wjff: 'Completion method',
    sjdw: 'Design company',
    sjrq: 'Design date',
    jkzzb: 'Wellhead ordinate',
    jkhzb: 'Wellhead abscissa',
    jkjd: 'Wellhead longitude',
    jkwd: 'Wellhead latitude',
    cpj: 'Magnetic declination',
    cqj: 'Magnetic dip',
    ccqd: 'Magnetic intensity',
    fwxzj: 'Azimuth correction Angle',
    dmhb: 'Ground elevation',
    gzwz: 'Tectonic position',
    dlwz: 'Geographical location',
    csjf: 'Whether cluster Wells',
    csjjzh: 'Cluster well group number',
    csjzks: 'Number of cluster Wells',
    csjzjyxh: 'Cluster hole number',
    fzjf: 'Branch well no',
    zjh: 'Main wellNO.',
    fzs: 'Number of branches',
    djfz: 'Which branch',
    fzdjs: 'Branch point depth',
    czjf: 'Whether lateral drilling',
    czyjh: 'Original sidetrack well number',
    czdjs: 'Sidetrack point depth',
    lrr: 'Enter person',
    lrrq: 'Enter date',
    xgr: 'Modifier',
    zjxgrq: 'Last modified date',
    remoteTransStart: 'Remote Trans Start',
    remoteTransFinish: 'Remote Transmission Finish',
    wellboreId: 'WellboreId',
    wellboreName: 'Wellborenumber',
    wellshape: 'Wellshape',
    drillstatus: 'Drillstatus',
    fracstatus: 'Frac status',
    islateral: 'Is lateral',
    isactive: 'Is active',
    suffixName: 'Suffix list name',
    ssljsjb: 'Ssljsjb',
    ssljmsjb: 'Ssljmsjb',
    sscjsjb: 'Sscjsjb',
    wcsssjb: 'Wcsssjb',
    ssylsjb: 'Ssylsjb',
    mudDateSheet: 'Mud Date Sheet',
    sskysjb: 'sskysjb',
    ssecdsjb: 'Ssecdsjb',
    ssmsesjb: 'Ssmsesjb',
    ssgeopsjb: 'Ssgeopsjb',
    ssfftorquesjb: 'Ssfftorquesjb',
    startDate: 'StartDate',
    DCYLXX: 'Formation Pressure Info',
    JDDS1: 'Top of wellsection',
    JDDS2: 'Bottom of wellsection',
    DCYLDLMDXX: 'FP ECD at B lower limit',
    DCYLDLMDSX: 'FP ECD at B upper limit',
    PLYLDLMDXX: 'FRAC ECD at B lower limit',
    PLYLDLMDSX: 'FRAC ECD at B upper limit',
    TTYLDLMDXX: 'TTYLDLMDXX',
    TTYLDLMDSX: 'TTYLDLMDSX',
    DCFCXX: 'Formation Hierarchical Info',
    zjrbxx: 'Daily drilling info', // title
    JIE: 'Erathem',
    XI: 'System',
    TONG: 'Series',
    ZU: 'Formation',
    DUAN: 'Member',
    YD: 'Sub-member',
    QUN: 'Group',
    YSDM: 'ColorCode',
    FXTS: 'RiskWarning',
    jxj: 'Inclination',
    fwj: 'azimuth',
    nzb: 'N coordinate',
    ezb: 'E coordinate',
    bhj: 'Closure distance',
    qjbhl: 'over-all angle change rate',
    jxbhl: 'Well inclination change rate',
    fwbhl: 'Azimuth change rate',
    sptycd: 'Horizontal projection lenth',
    tywy: 'Projection displacement',
    bhfw: 'Closure position',
    gjm: 'Tool face',
    zxl: 'Deflecting rate',
    bdmc: 'Target name',
    bklx: 'Target frame type',
    ybbj: 'Radius of circle target',
    jxbbg: 'Rectangular target and a half high',
    jxbbk: 'Rectangular target and a half width',
    jsjgxx: 'Well structure info', // title
    kzcx: 'Drilling sequence',
    ztcc: 'Bit size',
    tgcc: 'Casing size',
    tgxs: 'Casing bottom depth',
    tggj: 'Casing level',
    tglx: 'Casing type',
    lxmc: 'Type name',
    tgds: 'Casing top depth',
    zsnfs: 'Cementing way',
    snfgd: 'Cement anti height',
    ztcc2: 'Bit size2',
    tgcc2: 'Casing size2',
    zjzhxx: 'Combinaion drilling string info', // title
    zjzhbh: 'Combinaion drilling string No.',
    qsjs: 'Initial well depth',
    zzjs: 'Terminate well depth',
    jdjytms: 'Well interval and use descriptions',
    zjzh: 'Combinaion drilling string',
    zjyxx: 'Drilling fluids info', // title
    fdh: 'Section No.',
    zjylx: 'Drilling fluids type',
    zjymdxx: 'Drilling fluids density lower limit',
    zjymdsx: 'Drilling fluids density upper limit',
    ldndxx: 'Funnel viscosity lower limit',
    ldndsx: 'Funnel viscosity upper limit',
    apilslxx: 'API filtration lower limit',
    apilslsx: 'API filtration upper limit',
    apilbhdxx: 'API filter cake thickness lower limit',
    apilbhdsx: 'API filter cake thickness upper limit',
    hthplslxx: 'HTHP filtration lower limit',
    hthplslsx: 'HTHP filtration upper limit',
    hthplbhdxx: 'HTHP filter cake thickness lower limit',
    hthplbhdsx: 'HTHP filter cake thickness upper limit',
    jql10mxx: 'Gel strength 10s lower limit',
    jql10msx: 'Gel strength 10s upper limit',
    jql10fxx: 'Gel strength 10min lower limit',
    jql10fsx: 'Gel strength 10min upper limit',
    sxndxx: 'Plastic viscosity lower limit',
    sxndsx: 'Plastic viscosity upper limit',
    dqlxx: 'Yield point lower limit',
    dqlsx: 'Yield point upper limit',
    lxzsxx: 'Fluidity index lower limit',
    lxzssx: 'Fluidity index upper limit',
    cdxsxx: 'Consistency coefficient lower limit',
    cdxssx: 'Consistency coefficient upper limit',
    ztsyxx: 'Bit usage info', // title
    xrjs: ' Start depth',
    qcjs: 'End depth',
    ztxhtj1: 'recommended bit type1',
    ztxhtj2: 'recommended bit type2',
    ztxhtj3: 'recommended bit type3',
    zyxx: 'WOB lower limit',
    zysx: 'WOB upper limit',
    zsxx: 'Rpm-revolution per minute lower limit',
    zssx: 'Rpm-revolution per minute upper limit',
    plxx: 'ROF lower limit',
    plsx: 'ROF upper limit',
    jxzsplus: 'Penetration rate+',
    jxzs: 'Penetration rate',
    ztyl: 'Bit dosage',
    pzzh: 'Nozzle combination',
    gcjdxx: 'Project schedule info', // title
    zjzyxm: 'Drilling project',
    jhsgts: 'Planned construction days',
    ljsgts: 'Accumulative construction days',
    rgjds: 'Artificial bottom hole deep',
    xrsj: 'Down the well time',
    qcsj: 'Draw the well time',
    zjdc: 'Drilling length',
    sgnr: 'Construction content',
    xjxh: 'Downhole No.',
    ztxh: 'Bit No.',
    ztxlh: 'Bit serial number',
    ztxingh: 'Bit model',
    iadcbh: 'IADC No.',
    xjcs: 'Downhole times',
    ztzj: 'Bit diameter',
    ztlx: 'Bit type',
    sccj: 'Manufacturer',
    cjlb: 'Manufacturer category',
    rjxd: 'Brand new degree when entry well',
    cjxd: 'Brand new degree when out well',
    pzzj1: '1#nozzle diameter',
    pzlx1: '1#nozzle type',
    pzzj2: '2#nozzle diameter',
    pzlx2: '2#nozzle type',
    pzzj3: '3#nozzle diameter',
    pzlx3: '3#nozzle type',
    pzzj4: '4#nozzle diameter',
    pzlx4: '4#nozzle type',
    pzzj5: '5#nozzle diameter',
    pzlx5: '5#nozzle type',
    pzzj6: '6#nozzle diameter',
    pzlx6: '6#nozzle type',
    pzzj7: '7#nozzle diameter',
    pzlx7: '7#nozzle type',
    pzzj8: '8#nozzle diameter',
    pzlx8: '8#nozzle type',
    pzzj9: '9#nozzle diameter',
    pzlx9: '9#nozzle type',
    pzzj10: '10#nozzle diameter',
    pzlx10: '10#nozzle type',
    jc: 'Footage',
    czjsj: 'Pure drilling time',
    qxzsj: 'Tripping time',
    khysj: 'Reaming and redressing time',
    ycmsqk: 'WOBteeth',
    zcmsqk: 'WOBbearings',
    zjmsqk: 'WOB diameter',
    npcmsqk: 'WOBinner',
    wpcmsqk: 'WOBouter',
    qxjgmstz: 'Wear characteristics of cutting structure',
    qxjgmswz: 'Wear characteristics of cutting position',
    zcmfmsqk: 'Wear of bit bearing seal',
    qzyy: 'Drilling causes',
    qtmstz: 'Other wear characteristics',
    tgmc: 'Casing name',
    zy: 'Pressure on bit',
    zs: 'Rpm-revolution per minute',
    dlgjjzs: 'Power tools and speed',
    pl: 'Rate of flow',
    lgyl: 'Standpipe pressure',
    md: 'Density',
    ldnd: 'Funnel viscosity',
    hsl: 'Sand content',
    hsl1: 'Sand content1',
    apilsl: 'API filtration',
    sxnd: 'Plastic viscosity',
    dql: 'Yield value',
    ztyj: 'Bit pressuredrop',
    hkyh: 'Annular pressure lost',
    slcjl: 'Jet impact force',
    pssd: 'Jet velocity',
    ztsgl: 'Bit hydraulic horsepower',
    bsgl: 'Specific water power',
    zgnzdsfsd: 'Mini up-and-down velocity in drill pipe',
    ztnzdsfsd: 'Mini up-and-down velocity in drill collar',
    gllyl: 'Power utilization',
    bgj1: '1#pump bore diameter',
    bcs1: '1#pump speed',
    bgj2: '2#pump bore diameter',
    bcs2: '2#pump speed',
    bgj3: '3#pump bore diameter',
    bcs3: '3#pump speed',
    bgj4: '4#pump bore diameter',
    bcs4: '4#pump speed',
    zgwj: 'Drill pipe outer diameter',
    ztwj: 'Drill collar outer diameter',
    zjyqyxx: 'Drilling fluids sample info', // title
    qysj: 'Sample time',
    qyjs: 'Wellsampling depth ',
    ckwd: 'Well outlet temp',
    ph: 'PH',
    jqls: 'Gel strength 10s',
    jqlmin: 'Gel strength 10min',
    apilbhd: 'API filter cakc thickness',
    mzxs: 'Coefficient of friction resistance',
    xzndjds3: 'Rotating viscometer readingsθ3',
    xzndjds6: 'Rotating viscometer readingsθ6',
    xzndjds100: 'Rotating viscometer readingsθ100',
    xzndjds200: 'Rotating viscometer readingsθ200',
    xzndjds300: 'Rotating viscometer readingsθ300',
    xzndjds600: 'Rotating viscometer readingsθ600',
    hthplsl: 'HTHP filtration',
    hthplbhd: 'HTHP filter cakc thickness',
    hyl: 'Oil content',
    hsl2: 'Water content',
    zxhl: 'Cutting content',
    zgxl: 'Total solid content',
    mbthl: 'MBT content',
    llzhl: 'Chloride ion content',
    glzhl: 'Calcium ion content',
    jlzhl: 'Calcium ion content',
    zkhd: 'Water total mineralization ',
    dsb: 'Dynamic plastic ratio',
    lxzs: 'Liquidity index',
    cdxs: 'Consistency coefficient',
    cw: 'Position',
    yysb: 'Ratio of oil to brine',
    jd: 'Alkalinity',
    jingd: 'Well team',
    prdy: 'Emulsion breaking voltage',
    gc: 'Domestic',
    jk: 'Import',
    warnGroupTable: 'Warn Group Table',
    warnUp: 'Upper limit of failure probability',
    warnDown: 'Lower limit of failure probability',
    warnLevel: 'Fault Level',
    seriesName: 'Series name',
    newSeries: 'New Series',
    seriesApplication: 'Series application',
    noticeTypeName: 'Notice Type',
    warnGroup: 'Warn User',
    fssj: 'Fssj',
    gk: 'Gk',
    fxmc: 'Fxmc',
    fsgl: 'Yjdj',
    zzjxxlb: 'List of drilling information',
    fscsj: 'Non-production time',
    completionTime: 'Completion Time',
    kc: 'Opening times',
    dc: 'Stratum',
    systemAlarmTime: 'System alarm time',
    alarmType: 'Alarm type',
    alarmContent: 'Alarm content',
    alarmLevel: 'Alarm level',
    handle: 'Handle',
    handleTime: 'Handle time',
    personHandling: 'Person handling',
    actionsProcessed: 'Actions processed',
    associatedUsers: 'Associated users',
    alarmProcessingHistory: 'Alarm processing history',
    otherWellSelect: 'Adjacent well selection',
    NoOtherWellSelect: 'No data',
    csbs: 'Bit usage frequency identification',
    zkycrq: 'Date of drilling',
    wzcjrq: 'Date of completion logging',
    jbmx: 'Classification details',
    jxmx: 'Type details',
    cbfs: 'Contract way',
    bqrq: 'Remove date',
    azrq: 'Installation date',
    kzsj1: '1st start time',
    wzsj1: '1st end time',
    kzsj2: '2nd start time',
    wzsj2: '2nd end time',
    kzsj3: '3rd start time',
    wzsj3: '3rd end time',
    kzsj4: '4th start time',
    wzsj4: '4th end time',
    kzsj5: '5th start time',
    wzsj5: '5th end time',
    kzsj6: '6th start time',
    wzsj6: '6th end time',
    kzsj7: '7th start time',
    wzsj7: '7th end time',
    kzsj8: '8th start time',
    wzsj8: '8th end time',
    wjsj: 'End time',
    jjsj: 'Transition time',
    zqsj: 'Before drill time',
    wjzq: 'Completion period',
    ztcssj: 'Halfway test time',
    zjzq: 'Drilling period',
    jjzq: 'Build period',
    yxhtjc: 'Valid backfill footage',
    bjfgjc: 'Rework footage',
    qxjc: 'Core footage',
    zjjcssl: 'Drill footage lost rate',
    sjcw: 'Design position',
    ycdbjs1: 'Oil layer top depth',
    ycdbjs2: 'Oil layer buttom depth',
    yxcd: 'Core lenth',
    yxshl: 'Core Yield rate',
    pjdtjc: 'Aver core single barrel footage',
    wzxs: 'Finish drill incline depth',
    wzczjs: 'Finish drill vertical depth',
    zjty: 'Rig months',
    tyxl: 'Rig Month rate',
    pjjxzs: 'Mean mechanical speed',
    fzsj: 'Branch time',
    czdtgcc: 'Side drill point casing dimensions',
    czkzsj: 'Side drill start time',
    czwzsj: 'Side drill end time',
    czsj1: '1st side drill time',
    czdjs1: '1st side drilldepth',
    czsj2: '2nd side drill time',
    czdjs2: '2nd side drilldepth',
    tzjf: 'Whether change well',
    qjf: 'Whether gas well',
    qphf: 'Whether beforebalance ',
    xjyf: 'Whether small borehole',
    txr: 'Written by',
    jsfzr: 'Technical director',
    scr: 'Examinate',
    tbdw: 'Fill units',
    gzmc: 'Structure name',
    cxwz: 'Lateral line position',
    hssd: 'sea depth',
    lrsd: 'Penetration depth',
    bxhb: 'Platform height above the ground ',
    ptbh: 'Platform No.',
    jd1: 'Longitude',
    wd: 'Latitude',
    wzcw: 'Finish drill position',
    gjkssj1: '1st well cementation start time ',
    gjjssj1: '1st well cementation end time',
    gjkssj2: '2nd well cementation starttime',
    gjjssj2: '2nd well cementation end time',
    gjkssj3: '3rd well cementation starttime',
    gjjssj3: '3rd well cementationend time',
    gjkssj4: '4th well cementation starttime',
    gjjssj4: '4th well cementationend time',
    gjkssj5: '5th well cementationstart time',
    gjjssj5: '5th well cementation endtime',
    gjkssj6: '6th well cementation starttime',
    gjjssj6: '6thwell cementation endtime',
    gjkssj7: '7th well cementationstart time',
    gjjssj7: '7th well cementation endtime',
    gjkssj8: '8th well cementation starttime',
    gjjssj8: '8th well cementation endtime',
    zjxh1: 'Drill tool NO.',
    zjmc: 'Drill tool name',
    zjsx: 'Drill tool abbr',
    cd: 'Lenth',
    wj: 'External diameter',
    nj: 'Internal diameter',
    bh: 'Wall thickness',
    zggj: 'Drill pipe steel grade',
    zgxjcd: 'drill pipe old/new level ',
    gczl: 'Nominal weight',
    qfqd: 'Yield strength',
    klqd: 'Tensile strength',
    knqd: 'Torsional strength',
    kjqd: 'Collapsing strength',
    knyqd: 'Internal pressure strength',
    fdzl: 'Subsection weight',
    ljzl: 'Cumulative weight',
    fdzdzxl: 'Subsection max thrust',
    fdzdwqyl: 'Subsection max bending stress ',
    fdzdnj: 'Subsection max torque',
    kjaqxs: 'Anti collapse safety factor',
    jtklaqxs: 'Static tensile safety factor',
    zjklaqxs: 'Drill tension safety factor',
    knaqxs: 'Torsional safety factor',
    misesxs: 'MISES safety factor',
    lrsj: 'Entry time',
    gxrq: 'Date',
    cs: 'Vertical depth',
    jsff: 'Calculate method',
    gmz: 'Gamma value',
    recordType: 'Alarm mode',
    byxx: 'Pump pressure lower limit',
    bysx: 'Pump pressure upper limit',
    czsj: 'Only drill time',
    noFarAway: 'No Far Away',
    farAway: 'Far Away',
    zjTime: 'Drilling time',
    dcsd: 'Formation depth',
    alarmSwitch: 'Alarm switch',
    designParamsMsg: 'Yellow displays design data',
    mudSheet: {
      mudColumn: 'Mud Parameters',
      value: 'Value',
      time: 'TIME',
      t1: 'T1',
      t2: 'T2',
      fv: 'FV',
      n: 'N',
      s: 'S',
      k: 'K',
      mwi: 'MWI',
      yp: 'YP',
      mg: 'MG',
      av: 'AV',
      pv: 'PV',
      ph: 'PH',
      cl: 'CL',
      ca: 'CA',
      mwo: 'MWO'
    },
    kgrq: 'Commencement date',
    wgrq: 'Completion date',
    jhkgrq: 'Planned commencement date',
    jhwgrq: 'Planned completion date',
    sgxx: 'Construction information'
  },
  title: {
    addPerson: 'AddPerson',
    selectedPerson: 'SelectedPerson',
    addWell: 'AddWell',
    selectedWell: 'SelectedWell'
  },
  drillState: { // 钻井状态
    finish: 'Finish drill',
    doing: 'Doing drill',
    waiting: 'Waiting drill',
    completion: 'Completion well in process',
    wellCompletion: 'Well completion',
    testing: 'Oil testing',
    starting: 'Start oil test',
    EndOfOilTest: 'end oil test'
  },
  pushState: {
    yes: 'Push in',
    no: 'Interrupt'
  },
  activate: { // 激活
    yes: 'Yes',
    no: 'No'
  },
  JB: { // 井別
    straight: 'Straight well',
    directional: 'Directional well'
  },
  JX: {
    explore: 'Explore well',
    exploit: 'Development well'
  },
  wellParams: {
    SPM1: 'SPM1',
    SPM2: 'SPM2',
    SPM3: 'SPM3',
    SPM4: 'SPM4',
    SPM5: 'SPM5',
    SPM6: 'SPM6',
    PIT1: 'PIT1',
    PIT2: 'PIT2',
    PIT3: 'PIT3',
    PIT4: 'PIT4',
    PIT5: 'PIT5',
    PIT6: 'PIT6'
  },
  sys: {
    name: 'Name',
    icon: 'Icon',
    parent: 'Parent',
    level: 'Level',
    multilingual: 'Front end internationalization',
    resourcePath: 'Front end resource path',
    componentPath: 'Front end componentPath',
    sort: 'Sort',
    grade: 'Grade',
    describe: 'Description',
    account: 'Account',
    password: 'PW',
    Confirmpassword: 'Confirm pw',
    nickname: 'Nickname',
    realname: 'RealName',
    IDcard: 'IDcard',
    tel: 'Tel',
    mail: 'Mail',
    depName: 'Dep name',
    company: 'Company',
    sinopecAuthenticatedUser: 'Sinopec Authenticated User',
    title: 'Post',
    country: 'Nationality',
    transferstatus: 'Transferstatus',
    status: 'Register status',
    registerNew: 'Register Now',
    registerType: 'Register Type',
    userGroup: 'UserGroup',
    workGroup: 'WorkGroup',
    rolename: 'Name',
    rolecode: 'Code',
    code: 'Code',
    userName: 'User Name',
    userCode: 'Usergroup Code',
    Permission: 'Permission name',
    permission: 'Permission',
    usergroupName: 'User group name',
    videoRoomName: 'Video room name',
    essentialInformation: 'Essential info',
    changePassword: 'Change pw',
    originalPassword: 'Original pw',
    newPassword: 'New pw',
    functionName: 'Function name',
    functionParentName: 'Function parent name',
    functionCode: 'Function Code',
    functionUsage: 'Function usage description',
    organization: 'Organization name',
    organizationParent: 'Parent organization',
    organizationType: 'Organization type',
    organizationRoot: 'Organization root',
    organizationAlias: 'Organization alias',
    moduleName: 'Module name',
    rolesList: 'Roles list',
    batchAudit: 'Batch audit',
    batchAssociatedRoles: 'Batch associated roles',
    pushOrNot: 'Push or not',
    pushOrNotS: 'Push or not',
    istransitFrac: 'Fracture post or not',
    istransitTesting: 'Oil test post or not',
    istransitPrectl: 'control pressure post or not'
  },
  time: {
    second: 'second',
    minutes: 'minutes'
  },
  complex: {
    sglx: 'Accident type',
    cw: 'Horizon',
    jz: 'Benchmark',
    alcx: 'Case query',
    alfx: 'Case analysis'
  },
  RtLoggingEntity: {
    depth: 'Well drill depth',
    tvb: 'Vertical depth',
    lagdepth: 'Lag depth',
    bitdep: 'Bit position',
    blockpos: 'Hook height',
    timedistInst: 'Drill time',
    rpm: 'Rpm',
    wob: 'Wob',
    hkload: 'Hkload',
    spp: 'Spp',
    cup: 'Cup',
    torque: 'Torque',
    spm1: 'Spm1',
    spm2: 'Spm2',
    spm3: 'Spm3',
    pit1: 'Pit1',
    pit2: 'Pit2',
    pit3: 'Pit3',
    pit4: 'Pit4',
    pit5: 'Pit5',
    pit6: 'Pit6',
    pit7: 'Pit7',
    pit8: 'Pit8',
    tpit: 'Tpit',
    mti: 'Mti',
    mto: 'Mto',
    mci: 'Mci',
    mco: 'Mco',
    mwi: 'Mwi',
    mwo: 'Mwo',
    mfi: 'Mfi',
    flowout: 'Flowout',
    // tdrpm: 'Tdrpm',
    // tdtorque: 'Tdtorque',
    zbc: 'Zbc',
    sjjs: 'Sjjs',
    rjc: 'Daily footage',
    zrjc: 'Daily footage',
    wellno: 'Wellno',
    wellBoreNo: 'WellBore No.',
    time: 'Time',
    dcexp: 'DCexp',
    gk: 'Working condition',
    zjzt: 'Zjzt',
    gas: 'Total hydrocarbon',
    c1: 'Methane',
    c2: 'Ethane',
    c3: 'Propane',
    ic4: 'IC4',
    nc4: 'NC4',
    ic5: 'IC5',
    nc5: 'NC5',
    co2: 'CO2',
    h2: 'H2',
    h2s1: 'H2S1',
    h2s2: 'H2S2',
    h2s3: 'H2S3',
    h2s4: 'H2S4',
    casingShoe: 'CasingShoe ECD',
    bottomHole: 'Bottomhole ECD',
    CasingShoePressure: 'CasingShoePressure',
    BottomHolePressure: 'BottomHolePressure',
    wobBit: 'WOB Bit',
    torqueBit: 'TorqueBit',
    maxFf: 'MaxFf',
    ztjxbn: 'Machanical specific energy bit',
    CuttingsConce: 'Cuttingsconce',
    pressureG: 'Annulus ECD',
    Inc: 'Inclination angle',
    depth_caseShoe: 'DepthcaseShoe',
    axialForce: 'AxialForce',
    frictionalResistance: 'Frictional resistance',
    syymgd: 'Hydrophthalmia liquid level height',
    hkymgd: 'Annular liquid level height',
  },

  Length: {
    Meter: 'Meter',
    Foot: 'Foot'
  },
  Logging: {
    DEVI: 'DEVI',
    HAZI: 'HAZI',
    AC: 'AC',
    CAL: 'CAL',
    SP: 'SP',
    RD: 'RD',
    bit: 'bit',
    DEN: 'DEN',
    GR: 'GR'
  },
  Velocity: {
    MinutePerMeter: 'MinutePerMeter',
    MinutePerFoot: 'MinutePerFoot'
  },
  RotationSpeed: {
    RadianPerMinute: 'RadianPerMinute'
  },
  Pressure: {
    Megapascal: 'Megapascal',
    PoundforcePerSquareFeet: 'PoundforcePerSquareFeet'
  },
  Force: {
    Kilonewton: 'Kilonewton',
    Kilopoundforce: 'Kilopoundforce'
  },
  Energy: {
    KilonewtonMeter: 'KilonewtonMeter',
    KilopoundforceFoot: 'KilopoundforceFoot'
  },
  Frequency: {
    PerMinute: 'PerMinute'
  },
  Transmissibility: {
    CubicMeter: 'CubicMeter',
    CubicFoot: 'CubicFoot'
  },
  Temperature: {
    DegreeCelsius: 'DegreeCelsius',
    DegreeFahrenheit: 'DegreeFahrenheit'
  },
  Conductivity: {
    SiemensPerMeter: 'SiemensPerMeter',
    SiemensPerFoot: 'SiemensPerFoot'
  },
  PressureGradient: {
    GramPerCubicCentimeterEq: 'GramPerCubicCentimeterEq',
    PoundPerCubicFootEq: 'PoundPerCubicFootEq'
  },
  VolumetricFlowRate: {
    LiterPerSecond: 'LiterPerSecond',
    CubicFootPerSecond: 'CubicFootPerSecond'
  },
  ProcDrillcrewEntity: {
    ljd: 'Logging crew',
    ljdxcdh: 'Logging crew spot phone',
    zjd: 'Drilling crew',
    zjdxcdh: 'Drilling crew spot phone'
  },
  DeBasicEntity: {
    sjjs: 'DesignWellDepth',
    sjcs: 'DesignVerticalDepth',
    dmhb: 'GroundElevation',
    jkzzb: 'WellheadOrdinates',
    jkhzb: 'WellheadAbscissa',
    jkjd: 'WellheadLongitude',
    jkwd: 'WellheadLatitude',
    cpj: 'MagneticDeclination',
    cqj: 'MagneticDip',
    ccqd: 'MagneticFieldIntensity',
    fwxzj: 'Azimuth correction angle',
    fzdjs: 'Branch point well depth',
    czdjs: 'Side Drilling Point Depth'
  },
  DeBha: {
    qsjs: 'Initial well depth',
    zzjs: 'Termination depth',
    ztcc: 'Bit size'
  },
  DeBit: {
    ztcc: 'Bit size',
    xrjs: 'Downhole depth',
    qcjs: 'Outlet depth',
    pzzh: 'Nozzle assembly',
    zyxx: 'Lower BOP Limit',
    zysx: 'Upper limit of drilling pressure',
    zsxx: 'Lower Speed Limit',
    zssx: 'Speed cap',
    plxx: 'Lower displacement limit',
    plsx: 'Upper displacement limit',
    jxzs: 'ROP'
  },
  DeFluid: {
    qsjs: 'Initial well depth', // 起始井深(m)
    zzjs: 'Termination depth', // 终止井深(m)
    ldndxx: 'Lower limit of funnel viscosity', // 漏斗黏度下限(s)
    ldndsx: 'Upper limit of funnel viscosity', // 漏斗黏度上限(s)
    apilslxx: 'API Lower Limit of Filtration Loss', // API滤失量下限(mL)
    hthplslxx: 'Lower limit of HTHP filtration capacity', // HTHP滤失量下限(mL)
    hthplslsx: 'HTHP Filtration Limit', // HTHP滤失量上限(mL)
    jql10mxx: 'Lower limit of static shear force 10 s', // 静切力10s下限(Pa)
    jql10msx: '10 s upper limit of static shear force', // 静切力10s上限(Pa)
    jql10fxx: 'Lower limit of static shear force  10 minutes', // 静切力10min下限(Pa)
    jql10fsx: '10 min upper limit of static shear force', // 静切力10min上限(Pa)
    sxndxx: 'Lower limit of plastic viscosity', // 塑性黏度下限(mPa•s)
    sxndsx: 'Plastic Viscosity Upper Limit', // 塑性黏度上限(mPa•s)
    dqlxx: 'Lower limit of dynamic shear force', // 动切力下限(Pa)
    dqlsx: 'Upper limit of dynamic shear force', // 动切力上限(Pa)
    phxx: 'Lower limit of PH value', // pH值下限
    phsx: 'Upper limit of PH value', // pH值上限
    cdxsxx: 'Lower limit of consistency coefficient', // 稠度系数下限(Pa•s)
    cdxssx: 'Upper limit of consistency coefficient', // 稠度系数上限(Pa•s)
    apilslsx: 'API Filtration Limit' // API滤失量上限(mL)
  },
  DeSchedule: {
    zzjs: 'Termination depth', // 终止井深(m)
    qsjs: 'Initial well depth', // 起始井深(m)
    apilsl: 'API filtration of drilling fluid', // 钻井液API滤失量
    hthplsl: 'High temperature and high pressure filtration of drilling fluid', // 钻井液高温高压滤失量
    hsl: 'Sand content of drilling fluid', // 钻井液含砂量
    mbthl: 'Bantu content of drilling fluid', // 钻井液坂土含量
    glzhl: 'Calcium ion content of drilling fluid', // 钻井液钙离子含量
    sxnd: 'Plastic viscosity of drilling fluid', // 钻井液塑性黏度
    qyjs: 'Drilling fluid sampling well depth', // 钻井液取样井深
    ldnd: 'Viscosity of drilling fluid funnel', // 钻井液漏斗黏度
    jqls: 'Drilling fluid 10s static shear', // 钻井液10s静切力
    apilbhd: 'API filter cake thickness of drilling fluid', // 钻井液API滤饼厚度
    hthplbhd: 'Thickness of high temperature and high pressure filter cake of drilling fluid', // 钻井液高温高压滤饼厚度
    zxhl: 'Cuttings content of drilling fluid', // 钻井液钻屑含量
    llzhl: 'Chloride ion content of drilling fluid', // 钻井液氯离子含量
    jlzhl: 'Potassium ion content', // 钾离子含量
    zkhd: 'Total salinity of drilling fluid', // 钻井液总矿化度
    dql: 'Dynamic shear force of drilling fluid', // 钻井液动切力
    cdxs: 'K value of consistency coefficient of drilling fluid', // 钻井液稠度系数k值
    ckwd: 'Outlet temperature of drilling fluid', // 钻井液出口温度
    jqlmin: '10s static force', // 10s静切力
    hyl: 'Oil content of drilling fluid', // 钻井液含油量
    zgxl: 'Total solid content of drilling fluid', // 钻井液总固相量
    dsb: 'Dynamic plastic ratio of drilling fluid', // 钻井液动塑比
    prdy: 'Oil based drilling fluid' // 油基钻井液
  },
  DeStruct: {
    ztcc: 'Bit size', // 钻头尺寸(mm)
    js: 'Well depth', // 井深(m)
    tgcc: 'Casing size', // 套管尺寸(mm)
    rgjds: 'Artificial bottom hole depth', // 人工井底深(mm)
    tgds: 'Casing top depth', // 套管顶深(m)
    tgxs: 'Casing Depth', // 套管下深(m)
    tgcc2: 'Casing size 2', // 套管尺寸2(mm)
    ztcc2: 'Bit size 2' // 钻头尺寸2(mm)
  },
  DeWellpath: {
    js: 'Well depth', // 井深(m)
    cs: 'Vertical depth', // 垂深(m)
    jxj: 'Well deviation angle', // 井斜角(°)
    fwj: 'Azimuth', // 方位角(°)
    nzb: 'N coordinates', // N坐标(m)
    ezb: 'E coordinates', // E坐标(m)
    bhj: 'Closure distance', // 闭合距(m)
    qjbhl: 'Full-angle rate of change', // 全角变化率(°/m)
    jxbhl: 'Well deviation change rate', // 井斜变化率(°/m)
    fwbhl: 'Azimuth change rate', // 方位变化率(°/m)
    sptycd: 'Horizontal projection length', // 水平投影长度(m)
    tywy: 'Projection displacement', // 投影位移(m)
    bhfw: 'Closed azimuth', // 闭合方位(°)
    zxl: 'Build rate', // 造斜率(°/100m)
    gjm: 'Tool Face', // 工具面(°)
    ybbj: 'Round target radius' // 圆靶半径(m)
  },
  ProcBha: {
    ztcc: 'Bit size', // 钻头尺寸(mm)
    xrjs: 'Downhole depth', // 下入井深(m)
    qcjs: 'Outlet depth', // 起出井深(m)
    zjdc: 'Drilling section length' // 钻进段长(m)
  },
  ProcBit: {
    ztzj: 'Drill diameter',
    xrjs: 'Downhole depth',
    qcjs: 'Outlet depth',
    pzzj1: '1#Nozzle diameter',
    rjxd: 'Wellbore freshness',
    cjxd: 'Wellbore freshness',
    pzzj3: '3#Nozzle diameter',
    pzzj2: '2#Nozzle diameter',
    pzzj4: '4#Nozzle diameter',
    pzzj5: '5#Nozzle diameter',
    pzzj6: '6#Nozzle diameter',
    pzzj7: '7#Nozzle diameter',
    pzzj9: '9#Nozzle diameter',
    pzzj8: '8#Nozzle diameter',
    pzzj10: '10#Nozzle diameter',
    jxzs: 'ROP',
    jc: 'Footage',
    khysj: 'Eye Expansion Time',
    czjsj: 'Pure Drilling Time',
    qxzsj: 'Drilling time',
    ztwj: 'Diameter of drill collar',
    zgwj: 'Drill pipe outer diameter',
    zs: 'speed',
    zy: 'Drilling pressure',
    lgyl: 'Riser pressure',
    pl: 'displacement',
    hsl: 'Sand content',
    md: 'density',
    ldnd: 'Funnel Viscosity',
    dql: 'yield point',
    sxnd: 'Plastic Viscosity',
    apilsl: 'API filtration capacity',
    slcjl: 'Jet impingement force',
    ztyj: 'Bit pressure drop',
    hkyh: 'Annular Pressure Consumption',
    ztsgl: 'Drill water power',
    pssd: 'Injection velocity',
    bsgl: 'Specific water power',
    gllyl: 'Power Utilization Rate',
    bgj2: '2 # Pump Cylinder Diameter',
    bgj1: '1# PumpCylinder Diameter ',
    bcs1: '1 # Pump Impulse Number',
    bcs3: '3 # Pump Impulse Number',
    bcs2: '2 # Pump Impulse Number',
    bgj3: '3 # Pump Cylinder Diameter',
    bgj4: '4 # Pump Cylinder Diameter',
    bcs4: '4 # Pump Impulse Number',
    qxrq: 'Coring date',
    qxStartDate: 'Coring start date',
    qxEndDate: 'Date of coring',
    acquisitionRate: 'Acquisition rate',
    coreDescription: 'Core description',
    xc: 'Core length',
    qxl: 'Coring rate'
  },
  ProcStructEntity: {
    ztcc: 'Bit size',
    js: 'Well depth',
    tgcc: 'Casing size',
    tgxs: 'Casing Depth',
    tgcc2: 'Casing size 2',
    tgds: 'Casing top depth',
    rgjds: 'Artificial bottom hole depth',
    ztcc2: 'Bit size 2'
  },
  ProcFluidSample: {
    qyjs: 'Sampling well depth',
    ckwd: '0utlet temperature',
    md: 'Density',
    ldnd: 'Funnel Viscosity',
    hsl: 'Sand content',
    jqls: 'Static shear force 10s',
    jqlmin: 'Static shear force 10 min',
    apilsl: 'API filtration capacity',
    apilbhd: 'API filter cake thickness',
    mzxs: 'Friction coefficient',
    hthplsl: 'HTHP Filtration Loss',
    hthplbhd: 'HTHP',
    hyl: '0il content',
    hsl2: 'Water content',
    zxhl: 'Drilling cuttings content',
    zgxl: 'Total solid content',
    mbthl: 'MBT content',
    llzhl: 'Chloride ion content',
    glzhl: 'Calcium ion content',
    jlzhl: 'Potassium ion content',
    zkhd: 'Total salinity',
    sxnd: 'Plastic Viscosity',
    dql: 'Yield point',
    dsb: 'Dynamic plastic ratio',
    cdxs: 'Consistency coefficient',
    prdy: 'Demulsification voltage'
  },
  ProcDailyrpt: {
    js: 'Well depth of the day ',
    jc: 'Daily drilling depth ',
    zjymd: 'Density ',
    zjyldnd: 'Funnel viscosity ',
    zjysxnd: 'Plastic viscosity ',
    zjyhsl: 'Sand content ',
    zjyapilsl: 'API Filtration Loss',
    zjydql: 'Yield point',
    zjymbthl: 'MBT content',
    zy: 'Drilling pressure ',
    pl: 'Displacement ',
    zs: 'Speed',
    bc: 'Pump blunt ',
    by: 'Pump pressure ',
    zjcs: 'Drilling parameters',
    njcs: 'Mud parameters',
    zjzh: 'BHA',
    dlgj: 'Power Tool',
    scxxqk: 'Production details',
    xybsgap: 'Next Construction Arrangement',
    shr: 'Auditor',
    drgk: 'working condition',
    zydz: 'Low WOB',
    zygz: 'High WOB',
    bya: 'Pump pressure',
    rwlx: 'Task type',
    jdqk: 'Supervision',
    xmbjjdry: 'Project dept and supervisors',
    gtfqwczxx: 'Solid waste disposal info'
  },
  lwd: {
    js: 'js',
    jztGama: 'jztGama',
    jztGamaU: 'jztGamaU',
    jztGamaD: 'jztGamaD',
    jztGamaL: 'jztGamaL',
    jztGamaR: 'jztGamaR',
    ydGama: 'ydGama',
    ydGamaU: 'ydGamaU',
    ydGamaD: 'ydGamaD',
    ydGamaL: 'ydGamaL',
    ydGamaR: 'ydGamaR',
    jxjMwd: 'jxjMwd',
    fwjMwd: 'fwjMwd',
    gjmMwdCx: 'gjmMwdCx',
    gjmMwdZl: 'gjmMwdZl'
  },
  smarthelmet: {
    remotePrompt: 'Remote prompt',
    remoteMsg: 'Initiate an assistance call to you',
    hangUp: 'Hang up',
    answer: 'Answer'
  },
  ivf: {
    GR: 'GR',
    SP: 'SP',
    BS: 'BS',
    LLD: 'LLD',
    LLS: 'LLS',
    'AC': 'AC',
    NPHI: 'NPHI',
    POTA: 'POTA',
    THOR: 'THOR',
    URAN: 'URAN',
    LL8: 'LL8',
    COND: 'COND',
    DEN: 'DEN',
    ILM: 'ILM',
    ILD: 'ILD',
    '4m-RT': '4m-RT',
    '25mR': '2.5m-R2.5',
    SDXM: 'SDXM',
    SDYM: 'SDYM',
    PFM: 'PFM',
    YMOD: 'YMOD',
    POIS: 'POIS',
    SH: 'SH',
    POR: 'POR',
    VSH: 'VSH',
    POG:	'POG',
    POM:	'POM',
    SDX:	'SDX',
    SDY:	'SDY',
    PFG:	'PFG',
    PCG:	'PCG',
    PAZ:	'PAZ',
    ARFA:	'ARFA',
    CDEN:	'CDEN',
    TS: 'TS',
    DSR: 'DSR',
    BULK:	'BULK',
    TXML:	'TXML',
    SMOD:	'SMOD',
    BMOD:	'BMOD',
    CB: 'CB',
    UR: 'UR',
    TOURTOUR:	'TOURTOUR',
    ST: 'ST',
    PMX: 'PMX',
    PMY: 'PMY',
    PMC: 'PMC',
    RESS:	'RESS',
    FII: 'FII',
    KV: 'KV',
    RF: 'RF',
    RG: 'RG',
    BRYP:	'BRYP',
    DTC: 'DTC',
    K: 'K',
    CZDCZD: 'CZDCZD'
  }
}
