import store from '@/common_store';

export const DEFAULTPATH = '/well/gis'
window.DEFAULTPATH = DEFAULTPATH

export async function getBaseData(flag) {
    // addRocketListener(useInfo)
    try {
        await store.dispatch('SZGZwell/loadGlobal')
        store.dispatch('SZGZwell/unitFactoryInit')
        // await Promise.all([store.dispatch("SZGZwell/loadPage", "historyTrack"), store.dispatch("SZGZwell/loadPage", "meterTrack"), store.dispatch("SZGZwell/loadGKColors")]) // 曲线的必要资源，优化可将它移走
        if(flag){
            const keys = Object.keys(store.getters.SZGZ_gkBaseColor)
            if (!(keys && keys.length)) await store.dispatch("SZGZwell/loadGKColors")
        }
        return Promise.resolve()
    } catch (error) {
        console.log(error)
        return Promise.reject()
    }
}
